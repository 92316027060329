import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bars',
  templateUrl: './progress-bars.component.html',
  styleUrls: ['./progress-bars.component.css']
})
export class ProgressBarsComponent {
  @Input() belly_value: number = 0; // Input for belly value (0-10)
  @Input() chest_value: number = 0; // Input for chest value (0-10)

  // Convert values to percentages
  get bellyPercentage(): number {
    return this.belly_value * 10;
  }

  get chestPercentage(): number {
    return this.chest_value * 10;
  }
}