<div>
  <canvas
    baseChart
    [datasets]="lineChartData"
    [labels]="lineChartLabels"
    [options]="lineChartOptions"
    [colors]="lineChartColors"
    [chartType]="lineChartType"
  >
  </canvas>
</div>
