import { Injectable } from '@angular/core';
import { ExerciseService } from './exercise/exercise.service';
import { UserService } from './user.service';
import { BehaviorSubject, of, forkJoin } from 'rxjs'
import { delay, mergeMap, map, tap, take } from 'rxjs/operators';
import { User } from 'firebase';
import { AuthService } from './auth.service';
import { getDateInString, getFirstWeekday } from '../helper/helper';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private _dashboardData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _patientDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  get DashboardData() {
    return this._dashboardData;
  }

  get PatientDetails() {
    return this._patientDetails;
  }

  clearDashboardData() {
    this._dashboardData.next(null);
  }
 

  removePatientFromList(uid: any) {
    const list = this._dashboardData.value.patients.filter(p => p.uid !== uid);
    this._dashboardData.next({ ...this._dashboardData.value, patients: list })
  }

  updateAppointments() {
    let list: any;
    this.userService.getAppointments({}).pipe(map(res => res['data'])).subscribe(res => {
      list = res;
    },
      err => { },
      () => {
        this._dashboardData.next({ ...this._dashboardData.value, appointments: list });
      });
  }

  updateAppointment(appointmentId: string, updatedData: any) {
    const appointment = this._dashboardData.value.appointments.find(a => a._id === appointmentId);
    for (const key in updatedData) {
      appointment[key] = updatedData[key];
    }
  }

  removeAppointment(appointmentId: string) {
    const list = this._dashboardData.value.appointments.filter(a => a._id !== appointmentId);
    this._dashboardData.next({ ...this._dashboardData.value, appointments: list })
  }

  getPatientAppointments(patiendId: string) {
    return structuredClone(this._dashboardData.value.appointments.filter(a => a.patient_id === patiendId));
  }
  getAppointments(patientId:string|undefined){
    return structuredClone(this._dashboardData.value.appointments.filter(a => a.patient_id === patientId));
  }

  constructor(private userService: UserService, private exerciseService: ExerciseService, private auth: AuthService) { }

  



}