<div class="measurement-container card mb-4 mx-3">
  <h3 class="measurement-header">
    {{ measurementName }}
  </h3>

  <div class="measurement-period-tabs">
    <button
      class="measurement-period-tab"
      style="word-break: break-word"
      *ngFor="let period of periods"
      [ngClass]="{
        'measurement-period-tab--active': selectedPeriod === period.key
      }"
      (click)="setPeriod(period.key)"
    >
      {{ "generalTerms.periods." + period.label | translate }}
    </button>
  </div>

  <div class="measurement-average">
    <div style="font-size: 18px; color: #989898">
      {{ "patientDetails.performanceOverview.average" | translate }}
    </div>
    <div style="color: #404040">
      <span style="font-size: 24px; font-weight: 700">{{ averageFlow }}</span>
      <span style="font-size: 16px; font-weight: 500">{{
        measurementUnit
      }}</span>
    </div>
  </div>

  <div class="measurement-chart-container" *ngIf="flowDataLoaded">
    <app-measurement-chart
      [plotData]="flowData"
      [type]="'measurements'"
      [period]="selectedPeriod"
    ></app-measurement-chart>
  </div>

  <div
    *ngIf="!flowDataLoaded"
    class="centered preloader d-flex justify-content-center align-items-center pb-5"
  >
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div class="measurement-date-range" *ngIf="selectedPeriod !== 'Custom Range'">
    <button style="all: unset; cursor: pointer" (click)="prevPeriod()">
      <img
        style="width: 24px"
        src="../../../../../assets/images/icons/left_angle.svg"
        alt=""
      />
    </button>
    <span style="color: #7a7a7a">{{ dateRange }}</span>
    <button style="all: unset; cursor: pointer" (click)="nextPeriod()">
      <img
        style="width: 24px"
        src="../../../../../assets/images/icons/right_angle.svg"
        alt=""
      />
    </button>
  </div>

  <div class="measurement-date-range" *ngIf="selectedPeriod === 'Custom Range'">
    <div class="d-flex g-3">
      <div class="d-flex g-3 flex-wrap">
        <label style="flex: 1">
          {{ "patientDetails.startDate" | translate }}
          <input
            type="date"
            class="form-control"
            [value]="startDate | date : 'yyyy-MM-dd'"
            (change)="onCustomStartDateChange($event.target.value)"
          />
        </label>

        <label style="flex: 1">
          {{ "patientDetails.endDate" | translate }}
          <input
            type="date"
            class="form-control"
            [value]="endDate | date : 'yyyy-MM-dd'"
            (change)="onCustomEndDateChange($event.target.value)"
          />
        </label>
      </div>

      <button
        class="btn btn-primary mb-2"
        style="height: 50px; align-self: flex-end"
        (click)="fetchMeasurements()"
      >
        OK
      </button>
    </div>
  </div>

  <div class="measurement-history" *ngIf="flowData">
    <div *ngFor="let entry of flowData">
      <div class="measurement-history-item" *ngIf="entry.value !== null">
        <div style="color: #7a7a7a">
          {{ entry.value }} {{ measurementUnit }}
        </div>
        <div style="color: #909090">
          {{ entry.date | date : "MMM d HH:mm" }}
        </div>
      </div>
    </div>
  </div>
</div>
