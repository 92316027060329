import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExerciseService } from '@services/exercise/exercise.service';

@Component({
  selector: 'app-exercise-result-analysis',
  templateUrl: './exercise-result-analysis.component.html',
  styleUrls: ['./exercise-result-analysis.component.css']
})
export class ExerciseResultAnalysisComponent implements OnInit {

  @Input() exercise: any
  @Input() user_id: string
  @Input() role: string

  @Input() date: string
  @Input() period: number
  positiveFeedback: string = '';
  negativeFeedback: string[] = [];
  showHints: boolean = false;
  isLoading: boolean = false

  constructor(private exerciseService: ExerciseService) {

  }
  ngOnInit(): void {
    this.getExerciseResults()
  }
  getExerciseResults() {
    if (this.role != 'patient') {
      this.isLoading = false
      this.showHints = false;
      return
    }
    if ((this.exercise.name === 'lip') || (this.exercise.name === 'diaphragm')) {
      this.showHints = false
      this.isLoading = true
      const requestData = {
        patient_uid: this.user_id,
        exercise_id: this.exercise.exercise_id,
        date: this.date,
        period: this.period
      }
      this.exerciseService.getSessionResults(requestData).subscribe(returnData => {
        try {
          const sessionData = returnData['data'];
          const comparisonArray = [];
          comparisonArray.push({ action: "Inhalation", value: sessionData.breathing_analysis.percz_analy_inhale.value });
          comparisonArray.push({ action: "Exhalation", value: sessionData.breathing_analysis.percz_analy_exhale.value });
          comparisonArray.push({ action: "Posture", value: sessionData.posture_analysis.percz_analy.value });
          comparisonArray.push({ action: "Shoulder", value: sessionData.shoulder_analysis.value });

          comparisonArray.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
          let counter = comparisonArray.length - 1;
          let bestElement = comparisonArray[counter];
          while (bestElement.action === 'Shoulder') {
            counter--;
            bestElement = comparisonArray[counter];
          }
          this.positiveFeedback = 'positive' + bestElement.action;

          for (let i = 0; i < 2; i++) {
            if (comparisonArray[i].value < 70) {
              let feedback = 'focus' + comparisonArray[i].action;
              this.negativeFeedback.push(feedback);
            }
          }
        } catch (error) {

        }
      },
        (error) => {

        },
        () => {
          this.isLoading = false
          this.showHints = true;
        });
    }

  }
}
