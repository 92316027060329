import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  NotificationService,
  Notification,
} from '@services/notification/notification.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';

// Libraries

// Services
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

// Models
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { Patient } from '@schemas/patient.model';
import { UserService } from '@services/user.service';
import { ExerciseService } from '@services/exercise/exercise.service';
import { AuthService } from '@services/auth.service';
import { GeneralService } from '@services/general/general.service';
import {
  convertUTCTimeToLocalTimeZone,
  getDateInString,
} from '@helpers/helper';
import { CalendarEvent } from 'angular-calendar';
import { StoreService } from '@services/store.service';
import { Exercise } from '@services/program-manager/Exercise';
import { filter, map } from 'rxjs/operators';
import { error } from 'console';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
})
export class IndexComponent implements OnInit,OnDestroy {
  @ViewChild('exampleInputEmail2') public options: any;
  @ViewChild('exerciseDetailsModal') public exerciseDetailsModalRef: any;

  // exerciseMode = false;

  @ViewChild('pieChart') private chart: any;

  public chosenPatient = {} as Patient;
  public newPatient = {
    firstName: null,
    lastName: null,
    illness: null,
  };

  private physiotherapistDetails: any;

  // NEW
  public userId: string;
  // public physiotherapist: Physiotherapist;
  public physiotherapist: any;
  public userType: string = 'physiotherapist';

  public patientsList: Array<Patient> = [];
  public exercisesList: Array<Exercise> = [];

  public allExercisesDates: any[] = [];
  public todayAllExercises: any[] = [];
  public todayExercises: any[] = [];
  public hasExerciseToday: boolean = false;
  public patientListSorted: string = 'none';
  public remainingPreviousExercises: any[] = [];
  events: CalendarEvent[] = [];
  public locale: string;
  public days: any;
  public donePercentage: number = 0;
  public subtitle = '';
  viewDate: Date = new Date();
  public clickCountName: number = 0;
  public clickCountBirthday: number = 0;
  public clickCountDonePer: number = 0;
  public clickCountIllnes: number = 0;
  public clickCountLastActivityDate: number = 0;
  public clickCountCreationDate: number = 0;
  private currentDate = getDateInString(new Date());

  public arrowIconName = 'fa fa-caret-down fa-2x mb-2';
  public arrowIconBirthdate = 'fa fa-caret-down fa-2x mb-2';
  public arrowIconProgramStatus = 'fa fa-caret-down fa-2x mb-2';
  public arrowIconIllness = 'fa fa-caret-down fa-2x mb-2';
  public arrowIconLastActivityDate = 'fa fa-caret-down fa-2x mb-2';
  public creationDate = 'fa fa-caret-down fa-2x mb-2';

  public newExercise = {} as Exercise;
  // public chosenExercise = {} as Exercise;
  public chosenExercise: any;
  public chosenTestExercise: any;

  public editExerciseName: string;
  public editExerciseCategory: string;

  public availableExercises: Array<Exercise> = [];

  public patientDetails: boolean = false;
  public newPatientOnboarding: boolean = false;

  public patientsFilterOptions = ['none', 'first_name', 'last_name', 'illness'];
  public patientsFilterSelected: string = 'none';
  public exerciseFilterOptions = [
    'none',
    'breathing',
    'lower_body',
    'stretch',
    'sit',
    'stand',
    'supine',
  ];
  public exerciseFilterSelected: string = 'none';
  public searchPatientText: string = '';
  public searchExerciseText: string = '';
  public patientFilterWarning: boolean = false;
  public hoverPatientRow: number = null;
  public hoverExerciseRow: number = null;
  public emailSent: boolean = false;

  // pagination
  public patientPage: number = 1;
  public patientPageSize: number = 8;

  public exercisePage: number = 1;
  public exercisePageSize: number = 5;

  // Alert
  public assignedExercisesExist = false;
  public userIdToken: any;

  // Notification
  public isModalNotificationOpen: boolean = false;
  public pacientNotifications: any = {};
  public notificationLimit: Number = 4;
  public notifications$: Observable<Notification[]>;
  public isListViewAll: boolean = true;

  // Test
  public testView: boolean = false;

  public patientToBeDeleted: any;
  public language: string;

  private _modal: any;
  filteredResult: any = [];
  subs:any[] = []
  constructor(
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private exerciseService: ExerciseService,
    private authService: AuthService,
    private generalService: GeneralService,

    private ngZone: NgZone,
    private storeService: StoreService
  ) {
    const sub = this.storeService.physio_dashboard_data$.subscribe((data) => {
      if (data?.exercises?.length > 0) {
        this.exercisesList = data.exercises;
        this.patientsList = [...data.patients].map((p) => {
          const p2 = {
            ...p,
            last_active_date: convertUTCTimeToLocalTimeZone(
              p.last_activity_date
            ),
          };
          if (p2.last_active_date == 'N/A') {
            p2.last_activity_date = new Date(0);
          } else {
            const transformedDateStr = p2.last_activity_date.replace(' ', 'T'); // Convert ' ' to 'T'
            const date = new Date(transformedDateStr + 'Z'); // Add 'Z' to explicitly indicate UTC
            p2.last_activity_date = date;
          }
          return p2;
        });
        this.notificationService
          .checkUnreadPatientsNotifications(
            this.patientsList.map((p: any) => p._id)
          )
          .subscribe((patientWithNotification) => {
            this.patientsList = this.patientsList
              .map((p: any) => {
                p.hasUnreadNoti = patientWithNotification[p._id];
                return p;
              })
              .sort((a: any, b: any) => {
                if (a.hasUnreadNoti && !b.hasUnreadNoti) return -1;
                if (!a.hasUnreadNoti && b.hasUnreadNoti) return 1;
                return (
                  b.last_activity_date.getTime() -
                  a.last_activity_date.getTime()
                );
              });
          });
        this.chosenTestExercise = data.selectedExercise;
        if (data.selectedExercise) this.testView = true;
      }
    });
    this.subs.push(sub);
    try {
      this.locale = this.translate.currentLang;
      this.translate.onLangChange.subscribe((value) => {
        this.translate.use(value.lang);
        this.locale = value.lang;
      });
      this.viewDate.setHours(0, 0, 0, 0);
      this.events = [];

      this.physiotherapist = this.authService.getAuthenticatedUser();
      this.physiotherapistDetails = this.authService.me;

      if (!this.authService.me.medical) {
        this.patientsFilterOptions[3] = 'contactReason';
      }
    } catch (err) {}

    //this.loadNotifications();
  }
  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe())
  }

  ngOnInit(): void {
    this.storeService.searches$.subscribe((val) => {
      this.searchPatientText = val?.searchPatientText ?? '';
      (this.searchExerciseText = val?.searchExerciseText ?? ''),
        (this.patientPage = val?.patientPage ?? 1);
      this.exercisePage = val?.exercisePage ?? 1;
      this.exerciseFilterSelected = val?.exerciseFilterSelected ?? 'none';
      this.patientsFilterSelected = val?.patientsFilterSelected ?? 'none';
    });
    this.language = this.authService.getAuthenticatedUser().locale;
    this.translate.onLangChange.subscribe((value) => {
      this.language = value.lang;
      this.locale = value.lang;
    });
  }

  toggleSortingDirection(value: any) {
    this.patientListSorted = value;
    if (this.patientListSorted === 'name') {
      this.clickCountName++;
      if (this.clickCountName % 2 === 1) {
        this.arrowIconName = 'fa fa-caret-up fa-2x mb-2';
      } else {
        this.arrowIconName = 'fa fa-caret-down fa-2x mb-2';
      }
    } else if (this.patientListSorted === 'birthdate') {
      this.clickCountBirthday++;
      if (this.clickCountBirthday % 2 === 1) {
        this.arrowIconBirthdate = 'fa fa-caret-up fa-2x mb-2';
      } else {
        this.arrowIconBirthdate = 'fa fa-caret-down fa-2x mb-2';
      }
    } else if (this.patientListSorted === 'done_percentage') {
      this.clickCountDonePer++;
      if (this.clickCountDonePer % 2 === 1) {
        this.arrowIconProgramStatus = 'fa fa-caret-up fa-2x mb-2';
      } else {
        this.arrowIconProgramStatus = 'fa fa-caret-down fa-2x mb-2';
      }
    } else if (this.patientListSorted === 'illness') {
      this.clickCountIllnes++;
      if (this.clickCountIllnes % 2 === 1) {
        this.arrowIconIllness = 'fa fa-caret-up fa-2x mb-2';
      } else {
        this.arrowIconIllness = 'fa fa-caret-down fa-2x mb-2';
      }
    } else if (this.patientListSorted === 'last_activity_date') {
      this.clickCountLastActivityDate++;
      if (this.clickCountLastActivityDate % 2 === 1) {
        this.arrowIconLastActivityDate = 'fa fa-caret-up fa-2x mb-2';
      } else {
        this.arrowIconLastActivityDate = 'fa fa-caret-down fa-2x mb-2';
      }
    } else if (this.patientListSorted === 'created_at') {
      this.clickCountCreationDate++;
      if (this.clickCountCreationDate % 2 === 1) {
        this.creationDate = 'fa fa-caret-up fa-2x mb-2';
      } else {
        this.creationDate = 'fa fa-caret-down fa-2x mb-2';
      }
    }
  }

  navigateTo(path) {
    this.newPatientOnboarding = true;
    this.router.navigate([`dashboard/physiotherapist/${path}`]);
  }
  prepareNewPatient() {
    this.newPatientOnboarding = true;
    this.router.navigate(['dashboard/physiotherapist/create-patient']);
  }

  closeExerciseModal() {
    this.chosenExercise = null;
  }

  // helper methods

  logout() {
    this.authService.logout();
  }

  getPatientDetails(patient) {
    this.chosenPatient = patient;
    this.patientDetails = true;
  }

  startExercise(exercise) {
    this.storeService.updatesSearches({
      searchPatientText: this.searchPatientText.toString(),
      searchExerciseText: this.searchExerciseText.toString(),
      exercisePage: this.exercisePage ?? 1,
      patientPage: this.patientPage ?? 1,
      patientsFilterSelected: this.patientsFilterSelected,
      exerciseFilterSelected: this.exerciseFilterSelected,
    });
    this.router.navigate([
      `dashboard/physiotherapist/exercise/${exercise._id}`,
    ]);
  }

  closeExerciseView() {
    this.storeService.clearSelectedExercise();
    this.router.navigate([`dashboard/physiotherapist`]);
  }

  openModal(content, exercise?, saveRef?: boolean) {
    if (exercise) {
      this.storeService.getExerciseById(exercise._id).subscribe(({ data }) => {
        this.chosenExercise = data;
        if (saveRef) {
          this._modal = this.modalService.open(content, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
          });
        } else {
          this.modalService.open(content, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
          });
        }
      });
    } else {
      if (saveRef) {
        this._modal = this.modalService.open(content, {
          size: 'lg',
          backdrop: 'static',
          keyboard: false,
        });
      } else {
        this.modalService.open(content, {
          size: 'lg',
          backdrop: 'static',
          keyboard: false,
        });
      }
    }
  }

  resetPassword() {
    const requestData = {
      email: this.authService.me.email,
    };
    this.generalService.sendPasswordResetEmail(requestData).subscribe(() => {
      this.emailSent = true;
      this.logout();
    });
  }

  useLanguage(language: string) {
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    localStorage.setItem('language', language);
    this.language = language;

    //this.getUser();
  }

  onPatientSearchTextChange() {
    if (
      this.patientsFilterSelected === 'none' &&
      this.searchPatientText !== ''
    ) {
      this.patientFilterWarning = true;
    } else {
      this.patientFilterWarning = false;
    }
  }

  reloadCurrentPage() {
    window.location.reload();
  }

  navigateToPatientDetails(patientId: string, isNotification) {
    this.storeService.updatesSearches({
      searchPatientText: this.searchPatientText.toString(),
      searchExerciseText: this.searchExerciseText.toString(),
      exercisePage: this.exercisePage ?? 1,
      patientPage: this.patientPage ?? 1,
      patientsFilterSelected: this.patientsFilterSelected,
      exerciseFilterSelected: this.exerciseFilterSelected,
    });

    if (isNotification) {
      this.router.navigate([patientId, 'notifications'], {
        relativeTo: this.route,
      });
    } else {
      this.router.navigate([patientId], {
        relativeTo: this.route,
      });
    }
  }

  toggleModal(p: any) {
    this.isModalNotificationOpen = !this.isModalNotificationOpen;
    if (this.isModalNotificationOpen) {
      this.loadNotifications(p._id);
    } else {
      this.notificationService.markAsReadByPatient(p._id).subscribe(
        (data) => {
          this.notificationService
            .checkUnreadPatientsNotifications(
              this.patientsList.map((p: any) => p._id)
            )
            .subscribe((patientWithNotification) => {
              this.patientsList = this.patientsList
                .map((p: any) => {
                  p.hasUnreadNoti = patientWithNotification[p._id];
                  return p;
                })
                .sort((a: any, b: any) => b.hasUnreadNoti - a.hasUnreadNoti);
            });
        },
        (error) => {}
      );
    }

    this.pacientNotifications = p;
  }

  loadNotifications(patientId: string): void {
    this.notifications$ = this.notificationService
      .getPhysioNotificationAboutPatient(patientId, this.locale)
      .pipe(
        map((notifications) =>
          notifications.filter((n) => n.status === 'unread')
        )
      );
  }
}
