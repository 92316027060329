import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-exercise-counter',
  templateUrl: './exercise-counter.component.html',
  styleUrls: ['./exercise-counter.component.css']
})
export class ExerciseCounterComponent {
  @Input("isSingle") isSingle: boolean;
  @Input("countType") countType: 'up' | 'down';
  @Input("totalValue") totalValue: number;
  @Input("value") value: number;
  @Input("leftValue") leftValue: number;
  @Input("rightValue") rightValue: number;
  @Input("valueName") valueName: string;
  @Input("leftValueName") leftValueName: string;
  @Input("rightValueName") rightValueName: string;

}
