import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { colors, getDateInString } from '@helpers/helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { ExerciseService } from '@services/exercise/exercise.service';
import { GeneralService } from '@services/general/general.service';
import { Http2Service } from '@services/http2.service';
import { IExercise } from '@services/program-manager/IExercise';
import { IProgramExercise } from '@services/program-manager/IProgramExercise';
import { getDateAsString } from '@services/program-manager/Program';
import { ProgramManagerService } from '@services/program-manager/program-manager.service';
import { StoreService } from '@services/store.service';
import { CalendarEvent } from 'angular-calendar';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUserProfile } from 'src/app/resolvers';

@Component({
  selector: 'app-programs-tab',
  templateUrl: './programs-tab.component.html',
  styleUrls: ['./programs-tab.component.css'],
  animations: [
    trigger('expandCollapse', [
      state('void', style({ height: '0px', overflow: 'hidden' })),
      state('*', style({ height: '*', overflow: 'hidden' })),
      transition(':enter', [
        style({ height: '0px', overflow: 'hidden' }),
        animate('500ms ease-out', style({ height: '*' }))
      ]),
      transition(':leave', [
        animate('500ms ease-in', style({ height: '0px', overflow: 'hidden' }))
      ])
    ])
  ]
})
export class ProgramsTabComponent implements OnInit, OnDestroy {
  @ViewChild('programsSection') programsSection: ElementRef;
  @ViewChild('calendarComponent') calendarComponent;
  me: IUserProfile;
  isDropdownOpen: boolean = false;
  activeProgramTab: number;
  exercisePrograms: any[] = [];
  allExercisePrograms: any[] = [];
  exerciseProgramsCopy: any[] = [];
  patientId: string;
  collapsed: boolean[] = [];
  editMode: Boolean[] = [];
  assignedDates: Date[][] = [];
  programSmallestDate: Date[] = [];
  programLargestDate: Date[] = [];
  programCalendarDates: any[][] = [];
  events: CalendarEvent[] = [];
  calendarEvents: CalendarEvent[] = [];
  singleProgramEvents: CalendarEvent[] = [];
  chosenProgram: any;
  locale: string = 'en'
  active_exercise_periods: boolean[];
  selectedExerciseOption: any;
  dropdownTop: number;
  dropdownLeft: number;
  dataArr: string[] = [];
  gifUrls: any[] = [];
  periodDropdownStates: { [key: number]: boolean } = {};
  selectedPeriod: { [key: string]: boolean } = { 0: true, 1: false, 2: false };
  chosenExercise: any;
  sub_events_listener: any;
  exercisesList: Array<IExercise>;
  separetedProgramExercises: {
    0: IExercise[],
    1: IExercise[],
    2: IExercise[]
  } = { 0: [], 1: [], 2: [] };
  viewDate: Date = new Date();
  todayExercises: any[];

  newDates: any[];
  tempExercises: any[];
  deletedExercisesList: any[];
  loading: boolean = true;
  private _counter: number = 0;
  periods: any[] = [
    { id: 0, name: 'morning' },
    { id: 1, name: 'afternoon' },
    { id: 2, name: 'evening' },
  ]
  IncreaseCounter() {

    this._counter++;
    if (this._counter == 2) {
      this.loading = false
      this._counter = 0;
    }
  }
  /**
   *
   */
  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private exerciseService: ExerciseService,
    private storeService: StoreService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private generalService: GeneralService,
    private http: Http2Service,
    private pm: ProgramManagerService,
    private toastr: ToastrService,
    
    private router: Router,
  ) {
    this.me = this.authService.me;
    this.locale = this.translate.currentLang;
    pm.setLanguage(this.locale);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang;
      pm.setLanguage(this.locale);
    });
  }
  get availablesExercisesList() {
    const availablesExercisesList = this.exercisesList


    return availablesExercisesList;
  }
  checkBreathingInputValidation(exercise) {
    if (exercise.repetitions < 0 || exercise.repetitions > 9) {
      return false;
    }
    var valid = true;
    exercise.actions.forEach((action) => {
      if (
        action.duration === undefined ||
        parseInt(action.duration) < 0 ||
        parseInt(action.duration) > 10
      ) {
        valid = false;
      }
    });

    return valid;
  }
  checkStretchInputValidation(exercise) {
    if (exercise.repetitions <= 0 || exercise.repetitions > 9) {
      return false;
    }
    let valid = true;
    exercise.actions.forEach((action) => {
      if ('count' in action) {
        if (
          !action.count ||
          parseInt(action.count) <= 0 ||
          parseInt(action.count) > 30
        ) {
          valid = false;
        }
      }
      if ('duration' in action) {
        if (
          !action.duration ||
          parseInt(action.duration) <= 0 ||
          parseInt(action.count) > 600
        ) {
          valid = false;
        }
      }
    });
    return valid;
  }

  checkLowerBodyInputValidation(exercise) {
    if (exercise.repetitions <= 0 || exercise.repetitions > 9) {
      return false;
    }
    let valid = true;
    exercise.actions.forEach((action) => {
      if ('count' in action) {
        if (
          !action.count ||
          parseInt(action.count) <= 0 ||
          parseInt(action.count) > 30
        ) {
          valid = false;
        }
      }
      if ('duration' in action) {
        if (
          !action.duration ||
          parseInt(action.duration) <= 0 ||
          parseInt(action.duration) > 600
        ) {
          valid = false;
        }
      }
    });
    return valid;
  }
  checkExerciseInputValidation(exercise) {
    let valid = true;
    if (
      (exercise.sets || exercise.sets === 0) &&
      (exercise.sets <= 0 || exercise.sets > 10)
    ) {
      return false;
    }
    if (exercise.set_break?.duration && exercise.set_break.duration < 25) {
      return false;
    }
    if (exercise.category == 'breathing') {
      valid = this.checkBreathingInputValidation(exercise);
    }
    if (exercise.category == 'stretch') {
      valid = this.checkStretchInputValidation(exercise);
    }
    if (exercise.category == 'lower_body') {
      valid = this.checkLowerBodyInputValidation(exercise);
    }
    if (exercise.category == 'breathing_time') {
      valid = exercise.actions[1].duration >= 15;
    }
    if (exercise.category == 'relaxation') {
      valid = exercise.actions[0].duration >= 15;
    }
    return valid;
  }

  confirmUpdateExercise(content, program?, component?) {
    for (const e of program.exercises) {
      e.sets = e.sets ?? 1;
      e.repetitions = e.repetitions ?? 1;
      for (const a of e.actions) {
        if ('duration' in a && a.name != "hold") {
          a.duration = a.duration ? a.duration : 1;
        }
      }
    }
    let programValid = true;
    for (const e of this.selectedProgramExercises) {
      const exerciseValid = this.checkExerciseInputValidation(e);
      if (!exerciseValid) {
        programValid = false;
        this.translate
          .get('toastr.exerciseProgramValidationError')
          .subscribe((res: string) => {
            if (e.display_name && e.display_name[this.locale]) {
              this.toastr.error(
                e.display_name[this.locale] +
                ' ' +
                res.substring(res.indexOf(' ') + 1)
              );
            } else {
              this.toastr.error(res);
            }
          });
      }
    }
    if (programValid) {
      this.openModal(content, program, component);
    }
  }
  closeExercisesList() {
    this.selectedExerciseOption = null;
    this.isDropdownOpen = false;
  }
  closeListenerCallBack(e: PointerEvent) {
    if (this.isDropdownOpen) {
      this.isDropdownOpen = false;
      this.selectedExerciseOption = null;
    }

  }
  ngOnInit(): void {
    this.viewDate.setHours(0, 0, 0, 0);
    this.patientId = this.route.parent?.snapshot.paramMap.get('id');

    document.addEventListener('click', this.closeListenerCallBack.bind(this));

    this.generalService.getAllGifs().subscribe((urls) => {
      this.gifUrls = urls;
      this.gifUrls.forEach((urlPromise) => {
        urlPromise.then((url) => {
          this.dataArr.push(url);
        });
      });
    });
    this.sub_events_listener = this.pm.all_events$.subscribe((list) => {
      this.calendarEvents = list;
      this.singleProgramEvents = [];

      this.singleProgramEvents = list;
    });
    this.storeService.getAllExercises().subscribe(data => {
      this.exercisesList = data;
      this.IncreaseCounter()
    })
    this.getAllExercisePrograms()
  }
  ngOnDestroy(): void {
    try {
      if (this.sub_events_listener) this.sub_events_listener.unsubscribe();
    } catch (error) { }
  }
  addNewExercise_m(period, selectedExercise) {
    const e = this.pm.getSelectedProgram().getExerciseById(selectedExercise._id)
    let is_period_selected = false
    if (e) {
      for (const d of e.assigned_dates) {
        if (d.period == period) {
          is_period_selected = true
          break;
        }
      }
    }

    if (!is_period_selected) {
      this.pm.addExercise({
        ...selectedExercise,
        _id: selectedExercise._id,
        exercise_id: selectedExercise._id,
        period
      });
    } else {
      this.pm.removeExercise(selectedExercise._id, period);
    }
    this.active_exercise_periods = [false, false, false]
    this.pm.getEditedProgram()
    const modified_e = this.pm.getSelectedProgram().getExerciseById(selectedExercise._id)
    if (modified_e) {
      modified_e.assigned_dates.forEach(d => {
        if (d.period != undefined)
          this.active_exercise_periods[d.period] = true
      })
    }
    return;

  }
  setSelectedPeriod(period) {
    if (this.selectedPeriod[period]) {
      this.selectedPeriod[period] = false;
      this.periodDropdownStates = {}
    } else {
      this.periodDropdownStates = {};
      this.selectedPeriod = { 0: false, 1: false, 2: false };
      this.selectedPeriod[period] = true;
    }
  }
  isExercisePeriodSelected(period: number) {
    return this.selectedExerciseOption.periods?.[period] == true
  }
  getYesterday = () => {
    const yesterDay = new Date()
    yesterDay.setDate(yesterDay.getDate() - 1)
    yesterDay.setHours(0, 0, 0, 0)
    return yesterDay
  }
  getToday = () => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return today
  }

  changeProgramTab(num: number) {
    this.activeProgramTab = num;
    if (this.activeProgramTab === 0) {
      this.exercisePrograms = this.allExercisePrograms.filter(
        (prog, i) => prog.active === true && this.programLargestDate[i] > this.getYesterday()
      );
    } else if (this.activeProgramTab === 1) {
      this.exercisePrograms = this.allExercisePrograms.filter(
        (prog, i) => (!prog.active && !prog.deleted || (prog.active && this.programLargestDate[i] < this.getToday()))
      );
    } else if (this.activeProgramTab === 2) {
      this.exercisePrograms = this.allExercisePrograms.filter(
        (prog,i) => prog.deleted && this.programLargestDate[i] 
      );
    }

    this.initializeCollapsableArray();
    this.initializeEditableMode(this.exercisePrograms);
    this.addAssignedDatesPerProgram(this.exercisePrograms);
    this.exercisePrograms.forEach((program, index) => {
      this.findSmallestAndLargestDatePerProgram(index);
      this.createProgramCalendarEvents(program);
    });
    // setTimeout(() => {
    //   this.programsSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // }, 10);
    setTimeout(() => {
      this.exerciseProgramsCopy = structuredClone(this.exercisePrograms);

    }, 10)
  }

  public initializeCollapsableArray() {
    for (let i = 0; i < this.exercisePrograms.length; i++) {
      this.collapsed[i] = true;
    }
  }

  initializeEditableMode(programs: any[]) {
    programs.forEach(() => {
      this.editMode.push(false);
    });
  }

  addAssignedDatesPerProgram(programs) {
    let programAssignedDates = [];
    programs.forEach((program) => {
      program.exercises[0].assigned_dates.forEach((exerciseDate) => {
        const date = exerciseDate.date.split('.');
        const assignDate = new Date(date[2], date[1] - 1, date[0]);
        if (!programAssignedDates.includes(assignDate)) {
          programAssignedDates.push(assignDate);
        }
      });
      this.assignedDates.push(programAssignedDates);
      programAssignedDates = [];
    });
  }
  findSmallestAndLargestDatePerProgram(index) {
    let date: string;
    let smallestDate: Date;
    let largestDate: Date;

    this.allExercisePrograms[index].exercises.forEach((exercise) => {
      if (exercise.assigned_dates.length > 0) {
        const dates = exercise.assigned_dates.map((date) => {
          let tempDate = date.date.split('.');
          return new Date(tempDate[2], tempDate[1] - 1, tempDate[0]);
        });
        if (!largestDate) {
          largestDate = new Date(Math.max.apply(null, dates));
        } else {
          let tempLargestDate = new Date(Math.max.apply(null, dates));
          if (tempLargestDate > largestDate) {
            largestDate = tempLargestDate;
          }
        }
        if (!smallestDate) {
          smallestDate = new Date(Math.min.apply(null, dates));
        } else {
          let tempSmallestDate = new Date(Math.min.apply(null, dates));
          if (tempSmallestDate < smallestDate) {
            smallestDate = tempSmallestDate;
          }
        }
      }
    });
    this.programSmallestDate[index] = smallestDate;
    this.programLargestDate[index] = largestDate;
  }
  createProgramCalendarEvents(exerciseProgram: any, index: number = -1) {
    const results = [];
    exerciseProgram.exercises.forEach((exercise) => {
      exercise.assigned_dates.forEach((assigned) => {
        try {
          const stringDay = assigned.date.split('.')[0];
          const stringMonth = assigned.date.split('.')[1];
          const stringYear = assigned.date.split('.')[2];

          const date = new Date(
            stringYear + '/' + stringMonth + '/' + stringDay
          );
          let currentTime = new Date();
          date.setHours(0, 0, 0, 0);
          currentTime.setHours(0, 0, 0, 0);
          let color = colors.yellow;
          let performed: boolean;

          if (date <= currentTime) {
            if (assigned.performed) {
              color = colors.green;
              performed = true;
            } else {
              if (date < currentTime) {
                color = colors.red;
                performed = false;
              }
            }
          }

          const exerciseEvent = {
            start: date,
            title: exercise.display_name
              ? exercise.display_name
              : exercise.name,
            color: color,
            allDay: true,
            id: exercise.exercise_id,
            meta: {
              exercise_id: exercise.exercise_id,
              program_id: exercise.program_id,
              isProgramDeleted: exerciseProgram.deleted,
              isExerciseDeleted: exercise.deleted,
            },

            performed: performed,
          };
          results.push(exerciseEvent);
        } catch (err) { }
      });
    });
    if (index > -1) {
      this.programCalendarDates[index] = results;
    } else this.programCalendarDates.push(results);
  }
  getProgramLength(program) {
    let count: number = 0;
    if (this.activeProgramTab === 2) {
      return program.exercises.length;
    }
    program.exercises.forEach((exercise) => {
      if (!exercise.deleted && exercise.assigned_dates?.length > 0) {
        count++;
      }
    });
    return count;
  }
  checkEmptyValue(event) {
    if (event.target.value === '') event.target.value = 0;
  }
  onExercisePropertyChange(exercise) {
    for (let period in this.separetedProgramExercises) {
      this.separetedProgramExercises[period].forEach((ex) => {
        if (exercise.exercise_id == ex.exercise_id) {

          ex.sets = exercise.sets;
          ex.repetitions = exercise.repetitions;
          ex.actions = JSON.parse(JSON.stringify(exercise.actions));
          ex.set_break = JSON.parse(JSON.stringify(exercise.set_break));
        }
      });
    }
  }
  toggleExerciseForPeriod(selectedExercise: any, period: number) {
    selectedExercise.assigned_dates.sort((a, b) => b.date - a.date);
    const latestDate = selectedExercise.assigned_dates[0].date
    const latestDateTime = DateTime.fromJSDate(latestDate); // Convert JS Date to Luxon DateTime
    const today = DateTime.now().startOf("day"); // Normalize to the start of today for accurate comparison

    if (latestDateTime < today) {
      //dont modify old assigned dates
      return
    }
    if (!this.isExerciseInPeriod(selectedExercise, period)) {
      this.pm.addExercise({
        ...selectedExercise,
        _id: selectedExercise.exercise_id,
        exercise_id: selectedExercise.exercise_id,
        period: period,
      });
    } else {
      this.pm.removeExercise(selectedExercise.exercise_id, period);
    }
  }
  expandProgram(num: number, program: any) {

    this.chosenProgram = program;
    this.collapsed.forEach((card, i) => {
      if (!card) {
        this.shrinkProgram(i);
        this.collapsed[i] = true;
      }
    });
    const today = new Date().setHours(0, 0, 0, 0);
    const tempEvents = this.calendarEvents;
    this.singleProgramEvents = [];
    this.singleProgramEvents = tempEvents;
    const diffExercisesInProgram: any = {};
    this.programCalendarDates[num].forEach((element) => {
      this.singleProgramEvents.forEach((value) => {
        if (value.start.toDateString() === element.start.toDateString()) {
          try {
            if (
              this.chosenProgram.program_id == element.meta.program_id &&
              element.meta.program_id == value.meta.program_id &&
              element.id == value.id
            ) {
              const eDate = value.start.setHours(0, 0, 0, 0);
              if (eDate >= today) {
                value.color = colors.blue;
              }
            }
          } catch (error) {

          }
        }
      });
    });

    this.pm.setSelectedProgram(program.program_id);
    this.pm.highLightProgramEvents();

    this.collapsed[num] = !this.collapsed[num];
  }


  openModal(content, program?, component?) {

    if (program) {
      this.chosenProgram = program;
    }
    if (component) {
      this.calendarComponent = component;
      // this.newDates = this.tempExercises;
    }
    return this.modalService.open(content, { size: 'lg' });
  }
  activeEditMode(index, content?) {
    if (this.editMode[index]) {
      this.openModal(content);

    } else {
      this.editMode[index] = true;
    }

    this.pm.highLightProgramEvents();
  }

  isExerciseInPeriod(exercise: any, period: number): boolean {
    return !!this.selectedProgramExercises.find(e => e.exercise_id == exercise.exercise_id && e.assigned_dates.find(ad => DateTime.fromJSDate(ad.date) >= this.today && ad.period == period))



    // const latestDate = exercise.assigned_dates[exercise.assigned_dates.length-1].date
    // const latestDateTime = DateTime.fromJSDate(latestDate); // Convert JS Date to Luxon DateTime
    // const today = DateTime.now().startOf("day"); // Normalize to the start of today for accurate comparison

    // if (latestDateTime >= today) {

    //   return exercise.assigned_dates[exercise.assigned_dates.length-1].period==period
    // }
    // return false
  }
  shrinkProgram(num: number) {
    this.collapsed[num] = !this.collapsed[num];
    const tempEvents = this.calendarEvents;
    this.singleProgramEvents = [];
    this.singleProgramEvents = tempEvents;
    this.programCalendarDates[num]?.forEach((element) => {
      this.singleProgramEvents.forEach((value) => {
        if (value.start.toDateString() === element.start.toDateString()) {
          if (
            element.title === value.title ||
            element.title[this.locale] === value.title ||
            element.title === value.title[this.locale] ||
            element.title[this.locale] === value.title[this.locale]
          ) {
            if (value.color === colors.blue) {
              value.color = colors.yellow;
            }
          }
        }
      });
    });

  }
  donothing(a, b) { }
  cancelEditProgram() {
    this.editMode.forEach((value, index) => {
      this.editMode[index] = false;
    });
    this.pm.onCancelEditing();
    this.pm.highLightProgramEvents();
  }

  selectExerciseOption(option, index, event: MouseEvent) {
    const e = this.pm.getSelectedProgram().getExercisesList().find(e => e.exercise_id == option._id)
    this.active_exercise_periods = [false, false, false]
    if (e) {
      e.assigned_dates.forEach(d => {
        if (d.period != undefined)
          this.active_exercise_periods[d.period] = true
      })
    }

    if (this.selectedExerciseOption === option) {
      this.selectedExerciseOption = null;
    } else {
      this.selectedExerciseOption = option;
    }

    const itemElement = document.querySelector(`#exerciseItem-${index}`);
    const listElement = document.querySelector('#exerciseList');

    if (itemElement && listElement) {
      const rect = itemElement.getBoundingClientRect();
      const rect2 = listElement.getBoundingClientRect();

      this.dropdownTop = rect.y - rect2.y + 70;
      this.dropdownLeft = rect.width - 18;
    } else {
      console.error('Element not found');
    }
  }
  getGifLinks(exerciseName: string): string | undefined {
    if (exerciseName === 'sit2stand_test') {
      exerciseName = 'squat.gif';
    }
    if (exerciseName === 'diaphragm') {
      exerciseName = 'breath.gif';
    }
    if (exerciseName === 'lip') {
      exerciseName = 'breath.gif';
    }
    return this.dataArr.find((link) => link.includes(exerciseName));
  }
  getExerciseGif(event, exercise) {
    event.target.src = '../../../assets/images/Breathment Logo_rev_white.png';
  }

  togglePeriodDropdown(index: number) {
    this.periodDropdownStates[index] = !this.periodDropdownStates[index];

  }

  isPeriodsDropdownOpen(index: number): boolean {
    return this.periodDropdownStates[index] || false;
  }
  openRemoveExerciseModal(content, exerciseProgram, exercise, pp) {

    this.chosenProgram = exerciseProgram;
    this.chosenExercise = exercise;

    this.modalService.open(content);
  }
  removeExercise() {
    const period = this.selectedPeriod[0] ? 0 : (this.selectedPeriod[1] ? 1 : 2)

    this.toggleExerciseForPeriod(this.chosenExercise, period)
  }
  get selectedProgramExercises(): IProgramExercise[] {
    return this.pm.getCurrentProgramExercises();
  }
  get activeProgramExercises(): Array<IProgramExercise[]> {
    return [this.filterProgramExercises(0), this.filterProgramExercises(1), this.filterProgramExercises(2)]
  }
  today = DateTime.now().startOf("day"); // Normalize to the start of today for accurate comparison
  filterProgramExercises(period: number) {
    //this.activeProgramTab == 0 || this.activeProgramTab == 1 || this.activeProgramTab == 2
    switch (this.activeProgramTab) {
      case 0: this.today = DateTime.now().startOf("day"); break;
      case 1:
      case 2: {
        const i = this.allExercisePrograms.findIndex(p => p.program_id == this.chosenProgram.program_id)
        const programDateTime = DateTime.fromJSDate(this.programLargestDate[i]).startOf("day");
        this.today = programDateTime.isValid ? programDateTime : this.today;
        break;
      }
    }



    return this.selectedProgramExercises?.filter(e => {
      if (this.activeProgramTab == 2) {

        return e.assigned_dates.length > 0
      }
      return e.assigned_dates.findIndex(d => DateTime.fromJSDate(d.date) >= this.today && d.period == period) > -1
    })
  }
  inputValidator(event: any, maxValue: number) {
    const pattern = /^[0-9]*$/;

    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');

    }
    if (event.target.value > maxValue) {
      event.target.value = Math.floor(event.target.value / 10);
    }
  }
  deleteExerciseProgram() {

    let deleteProgram = true;
    this.chosenProgram.exercises = this.chosenProgram.exercises.map((e) => ({
      ...e,
      deleted: true,
    }));

    const dates = this.chosenProgram.exercises[0].assigned_dates.map((d) => {
      return { ...d, date: new Date(d.date.split('.').reverse().join('.')) };
    });
    const sortedDates = dates.sort((a, b) => {
      if (a.date > b.date) return 1;
      if (a.date < b.date) return -1;
      return 0;
    });

    if (sortedDates?.length > 0) {
      if (sortedDates[0].date < this.viewDate) {
        deleteProgram = false;
      } else if (
        sortedDates[0].date.toDateString() === this.viewDate.toDateString()
      ) {
        this.chosenProgram.exercises.forEach((exercise) => {
          const date = exercise.assigned_dates.find(
            (d) =>
              new Date(d.date.split('.').reverse().join('.')).toDateString() ===
              this.viewDate.toDateString()
          );
          if (date && date.performed) {
            deleteProgram = false;
          }
        });
      }
    }

    if (deleteProgram) {
      const onError = (error) => {
        this.storeService.displaySpinner.next(false);
        this.translate
          .get('toastr.deleteExerciseProgramError')
          .subscribe((res: string) => {
            this.toastr.error(res);
          });
      };
      const onCompleted = () => {
        this.storeService.displaySpinner.next(false);
        this.translate
          .get('toastr.deleteExerciseProgramSuccess')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
        // this.ngZone.run(() => {
        //   this.dataLoaded = false;
        // });
        this.clearVariables();
        this.getAllExercisePrograms();
      };
      this.storeService.refreshData$.next(true);
      this.storeService.displaySpinner.next(true);
      this.exerciseService
        .deleteExerciseProgram({
          patient_uid: this.patientId,
          program_id: this.chosenProgram.program_id,
          current_date: DateTime.now().toFormat('dd.MM.y'),
        })
        .subscribe((data) => {

        }, onError, onCompleted);
    } else {
      this.calendarComponent.selectedDates = [];

      this.updateExerciseProgram(true);
    }
    this.todayExercises = [];

  }
  getAllExercisePrograms() {
    this.loading = true
    this.http.fetch('get_exercise_program', {
      current_date: getDateInString(new Date()),
      patient_uid: this.patientId,
    })
      .pipe(map((res) => {
        res.data = res.data.map(pro => {
          pro.exercises = pro.exercises.map(e => {
            return {
              ...e,
              initial_pose: {
                ...e.initial_pose,
                check: e.initial_pose.check == true || typeof e.initial_pose.check == 'undefined'
              }
            }
          })
          return pro
        })
        return res.data
      }))
      .subscribe(data => {
        this.allExercisePrograms = data
        this.pm.setPrograms(data);
        this.allExercisePrograms.forEach((program, index) => {
          this.findSmallestAndLargestDatePerProgram(index);
          this.createProgramCalendarEvents(program);
        });

        this.changeProgramTab(0);
        this.IncreaseCounter()
        this.loading = false
      })
  }
  validateValues(program) {
    for (const e of program.exercises) {
      e.sets = e.sets ?? 1;
      e.repetitions = e.repetitions ?? 1;
      for (const a of e.actions) {
        if ('duration' in a && a.name != 'hold') {
          a.duration = a.duration ? a.duration : 1;
        }
      }
    }
  }
  updateExerciseProgram(fromDelete?: boolean) {

    try {
      if (this.sub_events_listener) this.sub_events_listener.unsubscribe();
    } catch (error) { }
    const edited = this.pm.getModifiedProgram();

    this.validateValues(edited);

    const edited_prog_exercises = structuredClone(edited.exercises);

    const requestData = {
      patient_uid: this.patientId,
      program_id: edited.program_id,
      exercises: edited_prog_exercises.map((e: any) => {
        e.patient = this.patientId;
        e.physiotherapist = this.me.id;
        e.assigned_dates = e.assigned_dates
          .filter((ad) => {
            if (fromDelete) {
              //if (ad.date.getTime() >= this.viewDate.getTime()) {
              if (!ad.performed) {
                e.deleted = true;
                return false;
              }
              //}
            }
            return true;
          })
          .map((ad) => {
            return {
              date: getDateAsString(ad.date),
              performed: ad.performed,
              period: ad.period
            }
          })
          .reduce((p, c) => {
            if (!p.find((d) => d.date == c.date && c.period == d.period)) {
              p.push(c);
            }
            return p;
          }, []);
        return e;
      }),
    };
    requestData.exercises = requestData.exercises.map((e) => {
      if (
        !this.pm.orginal_copy.exercises.find(
          (ex) => ex.exercise_id == e.exercise_id
        )
      ) {
        delete e._id;
      }
      return e;
    });

    this.storeService.displaySpinner.next(true);
    this.exerciseService.updateExerciseProgram(requestData).subscribe(
      (data) => { },
      (error) => {
        this.storeService.displaySpinner.next(false);
        if (!fromDelete) {
          this.translate
            .get('toastr.updateExerciseProgramError')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
        } else {
          this.translate
            .get('toastr.deleteExerciseProgramError')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
        }
      },
      () => {
        this.storeService.updatePatientTreatmentProgressLocaly(this.patientId);
        this.getAllExercisePrograms();
        this.editMode.forEach((value, index) => {
          this.editMode[index] = false;
        });
        if (!fromDelete) {
          this.translate
            .get('toastr.updateExerciseProgramSuccess')
            .subscribe((res: string) => {
              this.toastr.success(res);
            });
        } else {
          this.translate
            .get('toastr.deleteExerciseProgramSuccess')
            .subscribe((res: string) => {
              this.toastr.success(res);
            });
        }


        this.storeService.refreshData$.next(true);
        (
          this.storeService.getPatientDetailsDashboardDataResolver(
            this.patientId
          ) as Observable<any>
        ).subscribe((_) => {
          this.sub_events_listener = this.pm.all_events$.subscribe((list) => {
            this.singleProgramEvents = [];

            this.singleProgramEvents = list;
          });
        }, err => {
          this.translate
            .get('toastr.getPatientDetailsError')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
          this.router.navigate(['dashboard']);
        });
        //this.clearVariables();
      }
    );
  }

  clearVariables() {
    this.chosenProgram = null;
    this.programCalendarDates = [];
    this.newDates = [];
    this.tempExercises = [];
    this.deletedExercisesList = [];
    this.editMode.forEach((value, index) => {
      this.editMode[index] = false;
    });
  }

  onDayClicked(clickedDate: Date) {
    const clickedDateTime = DateTime.fromJSDate(clickedDate); // Convert JS Date to Luxon DateTime
    const today = DateTime.now().startOf("day"); // Normalize to the start of today for accurate comparison

    if (clickedDateTime >= today) {
      let dateToCopyEventsFrom = DateTime.now().startOf("day")
      if (this.activeProgramTab > 0) {
        const i = this.allExercisePrograms.findIndex(p => p.program_id == this.chosenProgram.program_id)
        const programDateTime = DateTime.fromJSDate(this.programLargestDate[i]).startOf("day");
        dateToCopyEventsFrom = programDateTime.isValid ? programDateTime : this.today;
      }
      this.pm.toggleClickedDateEvents(clickedDate, dateToCopyEventsFrom);
    }
  }
}
