import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Http2Service } from '@services/http2.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MeasurementService {
  public baseUrl: string;
  constructor(private http: Http2Service) {
    this.baseUrl = environment.baseCpuUrl;
  }

  getAllMeasurements(patientId: string, language?: string) {
    console.log(language);
    language = (language || 'en').toLocaleLowerCase();
    let requestData: any = {
      patient_uid: patientId,
      language: language,
      id_token: JSON.parse(localStorage.getItem('__auth'))?.access_token,
    };

    return this.http
      .fetch(`${this.baseUrl}get_all_measurements`, requestData)
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  getAllMeasurementsByRange(
    measurementId: string,
    startDate: string,
    endDate: string,
    patientUid: string,
    grouping_keyword?: string
  ) {
    grouping_keyword = grouping_keyword || 'D';
    let requestData: any = {
      id_token: JSON.parse(localStorage.getItem('__auth'))?.access_token,
      measurement_id: measurementId,
      start_date: startDate,
      end_date: endDate,
      grouping_keyword,
      patient_uid: patientUid,
    };

    return this.http
      .fetch(`${this.baseUrl}get_patient_measurement_by_range`, requestData)
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }
}
