<div id="div500">
    <div class="card border-0 shadow-lg z-depth" style="background-color: #374f6b; min-width: 60vw;">
        <div class="card-body pb-5">
            <h1 class="text-white text-center"
                style="font-family: 'Outfit', sans-serif; font-size: 75px; font-weight: 500;">{{ 'exercise.greatWork' |
                translate }}</h1>
            <div *ngIf="isLoading" class="full-screen-center">
                <div id="spinner" class="spinner-border"></div>
            </div>
            <div *ngIf="showHints" >
                <h4 class="text-white p-1"><span><i class="fas fa-award" style="color: white;"></i></span> {{
                    'exercise.' + positiveFeedback | translate }}</h4>
                <h2 *ngIf="negativeFeedback.length > 0" class="text-white mt-4">{{ 'exercise.hints' | translate }}:</h2>
                <div *ngFor="let feedback of negativeFeedback; let i = index;">
                    <h4 class="text-white p-1"><span><i class="fas fa-angle-double-up" style="color: white;"></i></span>
                        {{ 'exercise.' + feedback | translate }}</h4>
                </div>
            </div>
        </div>
    </div>
    

</div>