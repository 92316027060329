<div *ngIf="loading" class="full-screen-center" ngClass="loading">
  <div class="text-primary" role="status">
    <img [src]="'assets/Logo/spinner-border.gif'" alt="Spinner" id="spinner">
  </div>
</div>
<div class="row" #programsSection>
  <div class="col-lg-12 col-md-12 col-sm-12 m-0">
    <div class="mt-3 mb-3">
      <h3>{{ "patientDetails.exercisePrograms" | translate }}</h3>
    </div>
    <ul class="nav nav-pills nav-fill mb-3">
      <li class="nav-item">
        <a class="nav-link" aria-current="page" [ngClass]="{ active: activeProgramTab === 0 }"
          (click)="changeProgramTab(0)">{{ "patientDetails.activePrograms" | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ active: activeProgramTab === 1 }" (click)="changeProgramTab(1)">{{
          "patientDetails.oldPrograms" | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ active: activeProgramTab === 2 }" (click)="changeProgramTab(2)">{{
          "patientDetails.deletedPrograms" | translate }}</a>
      </li>
    </ul>
    <div *ngIf="exercisePrograms.length === 0" class="row">
      <div class="col-12 card p-4">
        <p class="text-center mb-0">
          {{ "patientDetails.noAssignedExercises" | translate }}
        </p>
      </div>
    </div>
    <div *ngIf="exercisePrograms.length > 0" class="row card-group">
      <div [id]="'div100'+i" *ngFor="let exerciseProgram of exerciseProgramsCopy; let i = index;" class="col-12">
        <ng-container *ngIf="programLargestDate[i]">
        <div *ngIf="collapsed[i]">
          <div class="card p-4" (click)="expandProgram(i,exerciseProgram)" style="cursor: pointer">
            <span><i class="fas fa-angle-down" style="float: right; color: #152c5b; cursor: pointer"></i></span>
            <div  class="row" style="align-items: flex-end">
              <div class="col-lg-3 col-md-3 col-sm-6">
                <h4>
                  {{ "patientDetails.program" | translate }} {{ i + 1 }}
                </h4>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6">
                <h5>
                  {{ getProgramLength(exerciseProgram) }}
                  <span *ngIf="getProgramLength(exerciseProgram) > 1">{{
                    "patientDetails.exercises" | translate
                    }}</span><span *ngIf="getProgramLength(exerciseProgram) === 1">{{ "patientDetails.exercise" |
                    translate }}</span>
                </h5>

              </div>
              <div   class="col-lg-3 col-md-3 col-sm-6 d-flex">
                <h5>{{ "generalTerms.start" | translate }}:</h5>
                <h5 class="pl-1 font-weight-light">
                  {{ this.programSmallestDate[i] | date : "dd.MM.yyyy" }}
                </h5>
              </div>
              <div  class="col-lg-3 col-md-3 col-sm-6 d-flex">
                <h5>{{ "generalTerms.end" | translate }}:</h5>
                <h5 class="pl-1 font-weight-light">
                  {{ this.programLargestDate[i] | date : "dd.MM.yyyy" }}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <!-- opened state -->
        <div *ngIf="!collapsed[i]">
          <div class="card p-4" [ngClass]="{ 'card-border-primary': editMode[i] }">
            <span><i class="fas fa-angle-up" style="float: right; color: #152c5b; cursor: pointer"
                (click)="shrinkProgram(i)"></i></span>
            <div class="d-flex mb-4">
              <h4 class="my-auto">
                {{ "patientDetails.program" | translate }} {{ i + 1 }}
              </h4>
              <span *ngIf="activeProgramTab !== 2" class="pl-4 my-auto">
                <i class="fas fa-edit icon-btn" style="
                    color: #384f6b;
                    cursor: pointer;
                    padding-right: 10px;
                  " (click)="activeEditMode(i, cancelModal)">
                </i>

                <div ngbDropdown class="w-full px-3" [(open)]="isDropdownOpen" [autoClose]="false"
                  (click)="(!editMode[i] && activeEditMode(i, cancelModal));$event.stopPropagation();">
                  <div class="exercise-options d-flex align-items-center justify-content-center w-full">
                    <i class="fas fa-plus" aria-hidden="true" style="font-size: 30px; color: #384f6b; cursor: pointer"
                      id="dropdownAddToTemplate" ngbDropdownToggle></i>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownAddToTemplate" style="width: max-content;">
                    <div class="d-flex align-items-center px-2" style="width: 100%;">
                      <p class="my-auto">{{ "generalTerms.search" | translate }}:</p>
                      <input type="text" class="form-control mx-2 my-1" id="search-text-template"
                        aria-describedby="search-text-template" style="height: 2.3rem;"
                        [(ngModel)]="searchExerciseTemplate" placeholder="{{ 'generalTerms.search' | translate }}" />
                    </div>

                    <div id="exerciseList" class="d-flex flex-column" style="max-height: 25rem; overflow-y: auto;">
                      <div
                        *ngFor="let exerciseOption of availablesExercisesList | searchFilter : 'exercise' : searchExerciseTemplate : 'none'; let i = index"
                        style="position: relative;" id="exerciseItem-{{i}}" class="container-hover">
                        <div class="d-flex mx-0 align-items-center w-100 container-hover"
                          style="display: flex; width: fit-content; flex-wrap: nowrap; cursor: pointer;" ngbDropdownItem
                          (click)="selectExerciseOption(exerciseOption, i); $event.stopPropagation()">
                          <div class="d-flex justify-content-center px-0"
                            style="height: 3.5rem; width: 4rem; max-width: 4rem;">
                            <img class="h-100 w-auto m-0" style="max-width: 100%;" alt="{{ exerciseOption.name }}"
                              [src]="getGifLinks(exerciseOption.name)"
                              (error)="getExerciseGif($event, exerciseOption)" />
                          </div>

                          <div class="d-flex px-0 ml-2 justify-content-between w-100">
                            <h6 class="lh-100 my-0 d-inline" style="font-size: 0.9rem;"
                              [ngClass]="{ 'selected-option' : selectedExerciseOption === exerciseOption }">
                              {{ exerciseOption.display_name[locale] ?? exerciseOption.display_name['en'] }}
                            </h6>

                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path [ngClass]="{ 'selected-option' : selectedExerciseOption === exerciseOption }"
                                d="M0.21967 13.7803C-0.0732233 13.4874 -0.0732233 13.0126 0.21967 12.7197L5.68934 7.25L0.21967 1.78033C-0.0732232 1.48744 -0.0732232 1.01256 0.21967 0.71967C0.512563 0.426777 0.987437 0.426777 1.28033 0.71967L7.28033 6.71967C7.57322 7.01256 7.57322 7.48744 7.28033 7.78033L1.28033 13.7803C0.987437 14.0732 0.512563 14.0732 0.21967 13.7803Z"
                                fill="#4A5568" />
                            </svg>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="selectedExerciseOption" style="position: absolute;
                        background-color: #fff;
                        box-shadow: 0px 0px 9.6px 0px #00000014;
                        border: 1px solid #EAECF3;
                        border-radius: 4px;" [style.top.px]="dropdownTop" [style.left.px]="dropdownLeft">
                        <ul id="ul2" class="d-flex flex-column m-0 p-0" style="list-style: none;">
                          <li
                            class="period-item font-weight-700 text py-2 px-3 d-flex align-items-center justify-content-start"
                            (click)="addNewExercise_m(0,selectedExerciseOption )">
                            <div class="d-flex" style="gap: 8px;">
                              <span [ngStyle]="{'visibility' :active_exercise_periods[0] ? 'visible' : 'hidden'}"
                                style="width : 18px; height : 16px">
                                <svg width="18" height="16" viewBox="0 0 18 16" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M0.999998 10.6423L5.11785 14.9006C5.54216 15.3394 6.25717 15.2989 6.62933 14.8151L17 1.33325"
                                    stroke="#0C90FF" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                </svg>
                              </span>
                              <span>
                                {{ 'patientDetails.addToMorning' | translate }}
                              </span>
                            </div>
                          <li
                            class="period-item font-weight-700 text py-2 px-3 d-flex align-items-center justify-content-start"
                            (click)="addNewExercise_m(1,selectedExerciseOption )">
                            <div class="d-flex" style="gap: 8px;">
                              <span [ngStyle]="{'visibility' : active_exercise_periods[1] ? 'visible' : 'hidden'}"
                                style="width : 18px; height : 16px">
                                <svg width="18" height="16" viewBox="0 0 18 16" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M0.999998 10.6423L5.11785 14.9006C5.54216 15.3394 6.25717 15.2989 6.62933 14.8151L17 1.33325"
                                    stroke="#0C90FF" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                </svg>
                              </span>
                              <span>
                                {{ 'patientDetails.addToAfternoon' | translate }}
                              </span>
                            </div>
                          </li>
                          <li
                            class="period-item font-weight-700 text py-2 px-3 d-flex align-items-center justify-content-start"
                            (click)="addNewExercise_m(2,selectedExerciseOption )">
                            <div class="d-flex" style="gap: 8px;">
                              <span [ngStyle]="{'visibility' : active_exercise_periods[2] ? 'visible' : 'hidden'}"
                                style="width : 18px; height : 16px">
                                <svg width="18" height="16" viewBox="0 0 18 16" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M0.999998 10.6423L5.11785 14.9006C5.54216 15.3394 6.25717 15.2989 6.62933 14.8151L17 1.33325"
                                    stroke="#0C90FF" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                </svg>
                              </span>
                              <span>
                                {{ 'patientDetails.addToEvening' | translate }}
                              </span>
                            </div>
                          </li>

                        </ul>
                      </div>
                    </div>

                  </div>
                </div>

                <i *ngIf="activeProgramTab !== 1" class="far fa-trash-alt icon-btn"
                  style="color: #823335; cursor: pointer" (click)="
                    openModal(
                      deleteExerciseProgramModal,
                      exerciseProgram,
                      assignedDates[i]
                    )
                  ">
                </i>
              </span>
            </div>

            <div class="d-flex flex-column hellome">

              <ng-container *ngFor="let p of [0,1,2]">
                <ng-container
                  *ngTemplateOutlet="programPeriodTemplate; context: { data:{period:p,editing:editMode[i],i} }"></ng-container>
              </ng-container>
            </div>
            <div *ngIf="exerciseProgram.exercises.length > 0" class="mt-5">
              <h4>{{ "patientDetails.assignedDates" | translate }} {{exerciseProgram?.exercises?.length}}</h4>
              <div class="row mt-3">
                <div class="col-lg-9 col-md-10 col-12 mx-auto">

                  <app-calendar [functionality]="editMode[i]?'program-update':'program-details'"
                    [exerciseList]="selectedProgramExercises" [assignedEvents]="singleProgramEvents"
                    (exerciseClicked)="donothing($event, calendarEventModal)"
                    (onDayClicked)="editMode[i]?onDayClicked($event):void(0)" #calendarComponent></app-calendar>
                </div>
              </div>
            </div>
            <div *ngIf="selectedProgramExercises.length === 0">
              <p class="text-center">
                {{ "patientDetails.emptyProgram" | translate }}
              </p>
            </div>
            <div *ngIf="editMode[i] " class="d-flex ml-auto mt-3">
              <button class="btn btn-sm btn-primary text-white" (click)="
                confirmUpdateExercise(
                  saveModal,
                    exerciseProgram,
                    calendarComponent
                  )
                ">
                {{ "generalTerms.save" | translate }}
              </button>
              <button class="btn btn-sm btn-secondary" (click)="openModal(cancelModal)">
                {{ "generalTerms.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #cancelModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      {{ "patientDetails.cancelEditExerciseProgram.title" | translate }}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      {{
      "patientDetails.cancelEditExerciseProgram.cancelQuestionText"
      | translate
      }}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" (click)="modal.close(); cancelEditProgram()">
      {{ "generalTerms.yes" | translate }}
    </button>
    <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
      {{ "generalTerms.no" | translate }}
    </button>
  </div>
</ng-template>


<ng-template #deleteExerciseProgramModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{ "patientDetails.deleteProgram" | translate }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ "patientDetails.deleteProgramQuestion" | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-danger" (click)="modal.close(); deleteExerciseProgram()">
      {{ "generalTerms.yes" | translate }}
    </button>
    <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
      {{ "generalTerms.no" | translate }}
    </button>
  </div>
</ng-template>
<ng-template #checkIcon let-show_icon="show_icon">
  <span style="display: inline-flex; align-items: center; font-size: 14px;width:18px;height:16px;padding:4px;">
    <ng-container *ngIf="show_icon">
      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.999998 10.6423L5.11785 14.9006C5.54216 15.3394 6.25717 15.2989 6.62933 14.8151L17 1.33325"
          stroke="#0C90FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </ng-container>
  </span>
</ng-template>

<ng-template #saveModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      {{ "patientDetails.saveEditExerciseProgram.title" | translate }}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      {{
      "patientDetails.saveEditExerciseProgram.cancelQuestionText" | translate
      }}
    </p>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" (click)="modal.close(); updateExerciseProgram()">
      {{ "generalTerms.yes" | translate }}
    </button>
    <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
      {{ "generalTerms.no" | translate }}
    </button>
  </div>
</ng-template>
<ng-template #removeExerciseModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title text-danger">{{ 'patientDetails.removeExerciseFromProgram.title' | translate }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <p>
        {{ 'patientDetails.removeExerciseFromProgram.removeExerciseQuestion1' | translate }}

        <span class="font-italic text-dark">{{
          chosenExercise["display_name"][locale] ?? chosenExercise["display_name"]['en']
          }}</span>
        {{ 'patientDetails.removeExerciseFromProgram.removeExerciseQuestion2' | translate }}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-danger" (click)="removeExercise(); modal.close()">
      {{ 'generalTerms.remove' | translate }}
    </button>
    <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
      {{ 'generalTerms.cancel' | translate }}

    </button>
  </div>
</ng-template>


<ng-template #programPeriodTemplate let-data="data">
  <div>
    <hr class="mt-2 mb-2" style="background-color: #EAECF3;">
    <div class="d-flex justify-content-between" (click)="setSelectedPeriod(data.period )" style="cursor: pointer;">
      <span>
        <span style="color: #000; font-weight: 700;">
          {{ ("periods."+data.period) | translate }}
        </span>
        ({{ activeProgramExercises[data.period]?.length }})
      </span>

      <img *ngIf="selectedPeriod[data.period]" [src]="'assets/img/svg/icons/arrow-dovn.svg'" style="cursor: pointer;">
      <img *ngIf="!selectedPeriod[data.period]" [src]="'assets/img/svg/icons/arrow-up.svg'" style="cursor: pointer;">
    </div>
    <div *ngIf="selectedPeriod[data.period]" @expandCollapse [ngClass]="{'expanded-div':selectedPeriod[data.period]}">

      <ng-container *ngFor="
   let exercise of activeProgramExercises[data.period] | filterDeletedExercises: activeProgramTab === 2 ? activeProgramTab : null;
   let j = index">
        <div class="responsive-container w-100 mx-2 px-4 mt-4 rounded bg-gray-100 d-flex py-2">
          <div *ngIf="!exercise.deleted || activeProgramTab === 2"
            class="left-container d-flex flex-row flex-wrap w-100 align-items-center my-4" style="flex: 3">
            <img alt="Image placeholder" [src]="getGifLinks(exercise.name)" (error)="getExerciseGif($event, exercise)"
              style="height: 6.5rem; width: 6.5rem;object-fit: contain;" />
            <div style="flex-grow: 1;" id="C40000">
              <i *ngIf="data.editing && !exercise.deleted" class="far fa-trash-alt"
                style="float: right; color: #823335; cursor: pointer" (click)="
        openRemoveExerciseModal(
          removeExerciseModal,
          exerciseProgram,
          exercise,99
        )
      "></i>
              <h6>
                {{
                exercise.display_name[locale]
                ? exercise.display_name[locale]
                : exercise.display_name['en']
                }}
              </h6>
              <p class="m-0">
                {{ "patientDetails.category" | translate }}:
                {{ "generalTerms." + exercise.category | translate }}
              </p>
              <div class="d-flex align-items-center mt-1" style="gap: 0.5rem">
                <p class="mb-0" style="margin-top: 0.2rem">{{ "generalTerms.aiTrainer" | translate }}</p>
                <ng-toggle *ngIf="data.editing" [(ngModel)]="exercise.initial_pose.check" [disabled]="!data.editing"
                  [color]="{
            unchecked: '#939da2',
            checked: '#33a1ff'
          }"></ng-toggle>
                <span *ngIf="!data.editing">{{(exercise.initial_pose.check ? 'generalTerms.enabled' :
                  'generalTerms.disabled') | translate}}</span>
              </div>
              <div class="d-flex row">
                <ul id="ul3" style="display: flex; flex-wrap: wrap; padding: 0px;padding-left: 15px;">
                  <li class="d-flex justify-content-center align-items-center" *ngFor="let opt of [0, 1, 2]"
                    (click)="data.editing && toggleExerciseForPeriod(exercise, opt)"
                    style="height: 40px;padding-right: 8px;">
                    <!-- Set a fixed height or adjust as needed -->

                    <input type="checkbox" [disabled]="!data.editing" [checked]="isExerciseInPeriod(exercise, opt)"
                      style="vertical-align: middle; margin-right: 5px;" />
                    <!-- Add space between checkbox and label -->

                    <label style="vertical-align: middle;padding:0;margin:0;">{{ ('periods.' + opt) | translate
                      }} </label>
                  </li>
                </ul>


              </div>
            </div>

          </div>

          <div *ngIf="!exercise.deleted || activeProgramTab === 2"
            class="right-container d-flex flex-row w-100 flex-wrap flex-column justify-content-center">
            <div class="d-flex justify-content-between">
              <!-- sets -->
              <div class="form-group m-0" style="text-align: center; width: 100px;">
                <h6>
                  {{ "createExerciseProgram.sets" | translate }}
                </h6>
                <p *ngIf="!data.editing">
                  {{ exercise.sets }}
                </p>
                <input *ngIf="data.editing" min="1" max="10" type="number" class="form-control"
                  [(ngModel)]="exercise.sets" (input)="inputValidator($event, 10)"
                  (blur)="checkEmptyValue($event); onExercisePropertyChange(exercise)"
                  [disabled]="exercise.name === 'sit2stand_test' || ['breathing_time', 'relaxation'].includes(exercise.category)"
                  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57" />
              </div>

              <!-- break -->
              <div *ngIf="
          !['breathing_time', 'relaxation'].includes(exercise.category)" class="form-group m-0"
                style="text-align: center; width: 100px;">
                <h6>
                  {{ "createExerciseProgram.break" | translate }} (s)
                </h6>
                <p *ngIf="!data.editing">
                  {{ exercise.set_break.duration }}
                </p>
                <input *ngIf="data.editing" min="25" type="number" class="form-control"
                  [(ngModel)]="exercise.set_break.duration "
                  (blur)="checkEmptyValue($event); onExercisePropertyChange(exercise)"
                  [disabled]="exercise.name === 'sit2stand_test'"
                  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57" />
              </div>

              <!-- break -->
              <div *ngIf="
          exercise.category === 'stretch' &&
          exercise.actions[1] &&
          (exercise.actions[1] | hasProp : 'duration')
        " class="form-group m-0" style="text-align: center; width: 100px;">
                <h6>
                  {{ "createExerciseProgram.break" | translate }}
                  (s)
                </h6>
                <p *ngIf="!data.editing">
                  {{ exercise.actions[1].duration }}
                </p>
                <input *ngIf="data.editing" type="number" class="form-control"
                  [(ngModel)]="exercise.actions[1].duration"
                  (change)="exercise.actions[3].duration = exercise.actions[1].duration"
                  (blur)="onExercisePropertyChange(exercise)" />
              </div>

              <!-- repetitions -->
              <div *ngIf="
          ![
            'sit2stand_test',
            'sit2stand_duration',
            'sit2stand_counter',
            'squat'
          ].includes(exercise.name) &&
          exercise.category !== 'breathing'
        " class="form-group m-0" style="text-align: center; width: 100px;">
                <h6>
                  {{ "createExerciseProgram.repetitions" | translate }}
                </h6>
                <p *ngIf="!data.editing">
                  {{ exercise.repetitions }}
                </p>
                <input *ngIf="data.editing" min="1" max="10" type="number" class="form-control"
                  [(ngModel)]="exercise.repetitions" (input)="inputValidator($event, 10)"
                  (blur)="checkEmptyValue($event); onExercisePropertyChange(exercise)"
                  [disabled]="['breathing_time', 'relaxation'].includes(exercise.category)"
                  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57" />
              </div>

              <!-- repetitions -->
              <div *ngIf="exercise.category === 'breathing'" class="form-group m-0"
                style="text-align: center; width: 100px;">
                <h6>
                  {{ "createExerciseProgram.repetitions" | translate }}
                </h6>
                <p *ngIf="!data.editing">
                  {{ exercise.repetitions }}
                </p>
                <input *ngIf="data.editing" min="1" max="10" type="number" class="form-control"
                  [(ngModel)]="exercise.repetitions" (input)="inputValidator($event, 10)"
                  (blur)="checkEmptyValue($event); onExercisePropertyChange(exercise)"
                  [disabled]="['breathing_time', 'relaxation'].includes(exercise.category)"
                  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57" />
              </div>

              <!-- duration -->
              <div *ngIf="
        exercise.category === 'lower_body'
        && exercise.actions[0] | hasProp : 'duration'
      " class="form-group m-0" style="text-align: center; width: 100px;">
                <h6>{{ "createExerciseProgram.duration" | translate }} (s)</h6>
                <p *ngIf="!data.editing">
                  {{ exercise.actions[0].duration }}
                </p>
                <input *ngIf="data.editing" type="number" class="form-control"
                  [(ngModel)]="exercise.actions[0].duration" (blur)="onExercisePropertyChange(exercise)" />
              </div>

              <!-- duration -->
              <div *ngIf="exercise.category === 'breathing_time'" class="form-group m-0"
                style="text-align: center; width: 100px;">
                <h6>{{ "createExerciseProgram.duration" | translate }} (s)</h6>
                <p *ngIf="!data.editing">
                  {{ exercise.actions[1].duration }}
                </p>
                <input *ngIf="data.editing" min="15" type="number" class="form-control"
                  [(ngModel)]="exercise.actions[1].duration" (blur)="onExercisePropertyChange(exercise)" />
              </div>

              <!-- duration -->
              <div *ngIf="exercise.category === 'relaxation' && exercise.actions[0].duration" class="form-group m-0"
                style="text-align: center; width: 100px;">
                <h6>{{ "createExerciseProgram.duration" | translate }} (s)</h6>
                <p *ngIf="!data.editing">
                  {{ exercise.actions[0].duration }}
                </p>
                <input *ngIf="data.editing" min="15" type="number" class="form-control"
                  [(ngModel)]="exercise.actions[0].duration" (blur)="onExercisePropertyChange(exercise)" />
              </div>

              <!-- count -->
              <div *ngIf="exercise.actions[0] | hasProp : 'count'" class="form-group m-0"
                style="text-align: center; width: 100px;">
                <h6>{{ "exercise.count" | translate }}</h6>
                <p *ngIf="!data.editing">
                  {{ exercise.actions[0].count }}
                </p>
                <input *ngIf="data.editing" type="number" min="1" max="30" class="form-control"
                  [(ngModel)]="exercise.actions[0].count" (input)="inputValidator($event, 30)"
                  (blur)="checkEmptyValue($event); onExercisePropertyChange(exercise)"
                  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57" />
              </div>

              <!-- count -->
              <div *ngIf="exercise.category === 'stretch' && exercise.actions[0].count" class="form-group m-0"
                style="text-align: center; width: 100px;">
                <h6>{{ "exercise.count" | translate }}</h6>
                <p *ngIf="!data.editing">
                  {{ exercise.actions[0].count }}
                </p>
                <input *ngIf="data.editing" type="number" class="form-control" [(ngModel)]="exercise.actions[0].count"
                  (change)="
            exercise.actions[2].count =
            exercise.actions[0].count" (blur)="onExercisePropertyChange(exercise)" />
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <!-- inhale -->
              <div *ngIf="exercise.category === 'breathing'" class="form-group m-0"
                style="text-align: center; width: 100px;">
                <h6>
                  {{ "createExerciseProgram.inhale" | translate }}
                  (s)
                </h6>
                <p *ngIf="!data.editing">
                  {{ exercise.actions[0].duration }}
                </p>
                <input *ngIf="data.editing" min="1" max="10" type="number" class="form-control"
                  [(ngModel)]="exercise.actions[0].duration" (input)="inputValidator($event, 10)"
                  (blur)="checkEmptyValue($event); onExercisePropertyChange(exercise)"
                  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57" />
              </div>

              <!-- hold -->
              <div *ngIf="exercise.category === 'breathing'" class="form-group m-0"
                style="text-align: center; width: 100px;">
                <h6>
                  {{ "createExerciseProgram.hold" | translate }}
                  (s)
                </h6>
                <p *ngIf="!data.editing">
                  {{ exercise.actions[1].duration }}
                </p>
                <input *ngIf="data.editing" min="0" max="10" type="number" class="form-control"
                  [(ngModel)]="exercise.actions[1].duration" (input)="inputValidator($event, 10)"
                  (change)="updateExerciseHoldDuration($event, exercise)"
                  (blur)="exercise.actions[1].duration = exercise.actions[1].duration ? exercise.actions[1].duration : 0;exercise.actions[3].duration = exercise.actions[3].duration ? exercise.actions[3].duration : 0" />
              </div>

              <!-- exhale -->
              <div *ngIf="exercise.category === 'breathing'" class="form-group m-0"
                style="text-align: center; width: 100px;">
                <h6>
                  {{ "createExerciseProgram.exhale" | translate }}
                  (s)
                </h6>
                <p *ngIf="!data.editing">
                  {{ exercise.actions[2].duration }}
                </p>
                <input *ngIf="data.editing" min="1" max="10" type="number" class="form-control"
                  [(ngModel)]="exercise.actions[2].duration" (input)="inputValidator($event, 10)"
                  (blur)="checkEmptyValue($event); onExercisePropertyChange(exercise)"
                  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57" />
              </div>
            </div>
          </div>

        </div>


      </ng-container>
    </div>
  </div>
</ng-template>