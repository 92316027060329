import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GeneralService } from '@services/general/general.service';

@Component({
  selector: 'app-visual-warning',
  templateUrl: './visual-warning.component.html',
  styleUrls: ['./visual-warning.component.css']
})
export class VisualWarningComponent implements OnChanges {
  @Input() warning: Record<string, any> | null = null; // Typed as a record for better type safety
  imagesUrls: string[] = [];
  timeoutHandlers: Map<string, NodeJS.Timeout> = new Map();

  constructor(private generalService: GeneralService) { }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['warning']?.currentValue && this.warning) {

      if (this.warning['posture_warning']) this.processWarnings(this.warning['posture_warning']);
      if (this.warning['shoulder_warning']) this.processWarnings(this.warning['shoulder_warning']);

    }
  }

  private processWarnings(warning: any): void {
    let fileName = '';

    if (warning.unit === 'boolean' && warning.value === true) {
      fileName = `${warning.display_name}.png`;
    } else if (warning.unit === 'direction' && warning.value !== 0) {
      fileName = `${warning.display_name}${warning.value === -1 ? '' : '+'}${warning.value}.png`;
    }

    if (fileName) {
      this.generalService.getFirebaseResourceUrl(`design/warnings/${fileName}`)
        .then(url => {
          this.addImageWithTimeout(fileName, url);
        })
        .catch(err => {
          console.error(`Failed to load resource: ${fileName}`, err);
        });
    }
  }

  private addImageWithTimeout(fileName: string, url: string): void {
    this.removeTimeoutHandler(fileName); // Ensure no existing handler remains
    if (this.imagesUrls.indexOf(url) == -1) {
      this.imagesUrls.push(url);
      //console.log('Added image:', url);
    }
    const handler = setTimeout(() => {
      this.removeTimeoutHandler(fileName);
      this.removeUrlFromList(url);
    }, 1000);

    this.timeoutHandlers.set(fileName, handler);
  }

  private removeTimeoutHandler(fileName: string): void {
    const handler = this.timeoutHandlers.get(fileName);
    if (handler) {
      clearTimeout(handler);
      this.timeoutHandlers.delete(fileName);
    }
  }

  private removeUrlFromList(url: string): void {
    const index = this.imagesUrls.indexOf(url);
    if (index !== -1) {
      this.imagesUrls.splice(index, 1);
      //console.log('Removed image:', url);
    }
  }

  onImageError(index: number): void {
    if (index >= 0 && index < this.imagesUrls.length) {
      this.imagesUrls[index] = 'assets/images/exercise-icons/warning-icon.png'; // Path to fallback image
      console.warn('Image load error, replaced with fallback at index:', index);
    }
  }
}
