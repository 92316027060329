<div class="progress-bar-container">
  <div class="bar">


    <div style="
    display: flex;
    width: 100%;
    position: absolute;
    justify-content: center;
">
      <span style="font-size: 10pt;color:#fff">
        {{ bellyPercentage | number: '1.2-2' }}%
      </span>

    </div>
    <div class="progress" [style.width.%]="bellyPercentage" style="background-color: #3d653d;">
    </div>
  </div>
</div>

<div class="progress-bar-container">
  <div class="bar">
    <div style="
    display: flex;
    width: 100%;
    position: absolute;
    justify-content: center;
">
      <span style="font-size: 10pt;color:#fff">
        {{ chestPercentage | number: '1.2-2' }}%
      </span>

    </div>
    <div class="progress" [style.width.%]="chestPercentage" style="background-color: #1075a5;">
    </div>
  </div>
</div>