<div class="tools"  #container>

    <span class="custom-btn" (click)="handlePauseResumeClick()">
        <svg *ngIf="!isPaused" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-icon">
            <path
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM224 192l0 128c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-128c0-17.7 14.3-32 32-32s32 14.3 32 32zm128 0l0 128c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-128c0-17.7 14.3-32 32-32s32 14.3 32 32z" />
        </svg>
        <svg *ngIf="isPaused" class="svg-icon" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path
                d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
        </svg>

    </span>
    <span class="custom-btn" (click)="handleCloseClick()">
        <i class="fas fa-times" style="font-size: 40px; color: #d8f3ff;"></i>
    </span>
</div>