import { Injectable } from '@angular/core';

import { environment } from "../../environments/environment";



import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Physiotherapist } from '../schemas/physiotherapist.model';
import { BehaviorSubject, of } from 'rxjs';
import { Patient } from '../schemas/patient.model';
import { tap } from 'rxjs/operators';
import { Http2Service } from './http2.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public baseUrl: string;

  private _patients_list: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    private afs: AngularFirestore,
    private http: Http2Service
  ) {
    // this.baseUrl = environment.baseUrl;
    this.baseUrl = environment.baseCpuUrl;
  }
  onLogout() {
    this._patients_list.next(null)
  }
  // NEW ENDPOINTS //
  getUserDetails(formData) {
    return this.http.fetch(this.baseUrl + 'get_user_details', formData ?? {}).pipe();
  }


  getTreatmentProgress(formData) {
    return this.http.fetch(this.baseUrl + 'get_exercise_program_completion_summary', formData).pipe();
  }
  getAllPatientsProgramSummary() {
    const d = new Date();
    const dateString = `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
    return this.http.fetch(this.baseUrl + 'get_all_patients_exercise_program_completion_summaries', {
      current_date: dateString
    }).pipe();
  }
  // create user
  createUser(formData) {
    return this.http.fetch(this.baseUrl + 'sign_up', formData).pipe();
    // return this.afs.collection('users').doc(`${userId}`).snapshotChanges();
  }

  // set user details
  setUserDetails(formData) {
    return this.http.fetch(this.baseUrl + 'set_user_details', formData).pipe();
  }

  editPersonalInfo(personalInfoModel) {
    return this.http.fetch(this.baseUrl + 'update_user_details', personalInfoModel).pipe();
  }


  //soft-delete patient

  deleteUser(formData) {
    return this.http.fetch(this.baseUrl + 'delete_user', formData).pipe();

  }

  getUserTasks(formData) {
    return this.http.fetch(this.baseUrl + 'get_user_tasks', formData).pipe();
  }

  addNewTask(formData) {
    return this.http.fetch(this.baseUrl + 'add_new_task', formData).pipe();

  }

  updateTask(formData) {
    return this.http.fetch(this.baseUrl + 'update_task', formData).pipe();
  }

  deleteTask(formData) {
    return this.http.fetch(this.baseUrl + 'delete_task', formData).pipe();
  }

  changeUserLanguage(formData) {
    return this.http.fetch(this.baseUrl + 'change_language', formData).pipe();
  }

  getSurveyResultsOverview(requestData) {
    return this.http.fetch(this.baseUrl + 'get_survey_results_by_time', requestData).pipe();
  }

  getStepCountsByRange(requestData) {
    return this.http.fetch(this.baseUrl + 'get_patient_step_count_by_date_range', requestData).pipe();
  }

  getPatientExerciseOverview(requestData) {
    return this.http.fetch(this.baseUrl + 'get_patient_exercise_performance_overview', requestData).pipe();
  }

  getActiveDays(requestData) {
    return this.http.fetch(this.baseUrl + 'get_active_days', requestData).pipe();
  }

  getLongestStreak(requestData) {
    return this.http.fetch(this.baseUrl + 'get_streak', requestData).pipe();
  }

  // NEW ENDPOINTS //

  getUser(userId: string) {
    return this.afs.doc(`users/${userId}`).valueChanges();
    // return this.afs.collection('users').doc(`${userId}`).snapshotChanges();
  }

  getPhysiotherapist(userId: string) {
    return this.afs.doc<any>(`physiotherapists/${userId}`).valueChanges();
  }

  // getPhysiotherapist(userId: string) {
  //   return this.afs.doc<Physiotherapist>(`physiotherapists/${userId}`).snapshotChanges();
  // }

  getPatientDetails(userId: string) {
    return this.afs.doc<Patient>(`patients/${userId}`).valueChanges();
  }

  // getPatientDetails(userId: string) {
  //   return this.afs.doc<Patient>(`patients/${userId}`).snapshotChanges();
  // }

  getPatients(userId: string) {
    return this.afs.doc<Patient>(`patients/${userId}`).valueChanges();
  }
  

  getExercises(id: string) {
    //return this.afs.doc<Exercise>(`exercises/${id}`).valueChanges();
  }

  // getExercises(id: string) {
  //   return this.afs.doc<Exercise>(`exercises/${id}`).snapshotChanges();
  // }

  updatePhysiotherapist(physiotherapist: Physiotherapist) {
    return this.afs.doc<Physiotherapist>(`physiotherapists/${physiotherapist.uid}`).set(physiotherapist, { merge: true });
  }

  updatePatient(patient: Patient) {
    return this.afs.doc<Patient>(`patients/${patient.uid}`).set(patient, { merge: true });
    // return this.afs.collection('patients').doc(patient.uid).set(patient, { merge: true });
  }

  getAppointments(requestData) {
    return this.http.fetch(this.baseUrl + 'retrieve_appointment_data', requestData).pipe();
  }

  createAppoinment(requestData) {
    return this.http.fetch(this.baseUrl + 'create_appointment', requestData).pipe();
  }

  updateAppointment(requestData) {
    return this.http.fetch(this.baseUrl + 'update_appointment', requestData).pipe();
  }

  deleteAppointment(requestData) {
    return this.http.fetch(this.baseUrl + 'delete_appointment', requestData).pipe();
  }

  updateStepGoal(requestData) {
    return this.http.fetch(this.baseUrl + 'set_patient_step_goal', requestData).pipe();
  }

  isEmailActive(requestData) {
    return this.http.fetch(this.baseUrl + 'is_email_active', requestData).pipe();
  }

  sendCustomerFeedback(requestData) {
    return this.http.fetch(this.baseUrl + 'send_customer_feedback', requestData).pipe();
  }
}
