<div *ngIf="!isExerciseFinished" class="container-fluid p-0 m-0" style="background-color: #000">

  <audio id="mp3AudioPlayer">
    <source type="audio/mp3" />
  </audio>
  <audio id="mp3AudioPlayer_beeps">
    <source type="audio/mp3" />
  </audio>
  <div *ngIf="show_exercise_video" class="exercise_video">

    <video autoplay playsinline controls src="{{ videoUri }}" style="height: 70vh; width: auto; object-fit: contain"
      id="main-instruction-video" #exercise_video_elem></video>
    <div style="position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      bottom: 30px;">

      <button class="btn text-white" style="background-color: #374f6b" (click)="onVideoSkipped()">
        {{ "generalTerms.next" | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="showSpinner && initializing"
    style="position: absolute;left:0;right:0;top:0;bottom:0;background-color: #f1f1f1;z-index: 1000000;">
    <div style=" position: absolute;  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);">

      <img src="../../../../assets/Logo/spinner-border.gif" alt="Spinner" id="spinner">
      <span>{{'loading exercise resources'}}</span>
    </div>

  </div>
  <div class="container" *ngIf="isExerciseReady && !isExerciseFinished">

    <div class="video-wrapper">


      <video id="cameraVideo" #cameraVideo autoplay class="camera"></video>
      <video *ngIf="isRelaxationExercise" id="relaxationVideos" #relaxationVideos autoplay></video>



      <div #overlayContainer class="overlay-container" [ngStyle]="controlButtonsStyle" style="height: 100vh;">



        <!-- <div style="position: absolute;top:0;left:0;width:100%;z-index: 9999999999;"
          *ngIf="currentExerciseState.actionTimer">

          <div [ngStyle]="{'width':'100%','overflow':'hidden'}">
            <div
              [ngStyle]="{'background': 'rgb(241 255 61 / 54%)','height': '10px','transition': 'width 1.20s ease-in-out','border-radius': '10px'}"
              [style.width.%]="((currentExerciseState.actionTimer.current)/(currentExerciseState.actionTimer.timeout/1000))*100">
            </div>
          </div>

        </div> -->

        <app-exercise-counter *ngIf="currentExerciseState.actionIndex>-1 && (isSingleCounter || isDoubleCounter)"
          [isSingle]="isSingleCounter" [value]="countersData.currentCounterValue" [leftValue]="countersData.leftValue"
          [rightValue]="countersData.rightValue" [countType]="count_type" [totalValue]="countersData.totalCounterValue"
          [valueName]="'totalCount'" [leftValueName]="'leftStretchCount'" [rightValueName]="'rightStretchCount'" />






        <app-filtered-image *ngIf="showSilhouatte()" [isVertical]="isVerticalExercise" [filterName]="silhouette_class"
          [imageUrl]="silhouette_image"></app-filtered-image>




        <app-progress-bars
          *ngIf="currentExerciseState?.belly_value!=undefined && currentExerciseState?.chest_value!=undefined "
          id="breathing-progress-bars" [belly_value]="currentExerciseState.belly_value"
          [chest_value]="currentExerciseState.chest_value"></app-progress-bars>
        <app-visual-warning [warning]="currentExerciseState?.aiResponse?.warning"></app-visual-warning>
      </div>
      <app-exercise-pause-resume-close (onCloseEvent)="onClose($event)" (onPauseResumeEvent)="pauseOrResume($event)"
        [ngStyle]="controlButtonsStyle" />

      <div [ngStyle]="timersContainerStyle" style="z-index: 21;justify-content: center;display: flex;margin-top: 10vh;height: auto;">
        <div [ngClass]="{'small_timer':show_small_count_down,'large_timer':show_count_down}"
          *ngIf="show_small_count_down || show_count_down">
          <circle-progress [percent]="countdownPercentage" [innerStrokeColor]="'transparent'"
            class="rounded-circle bg-translucent-secondary" [maxPercent]="100" [subtitleFontSize]="80"
            [subtitle]="count_down_value" [subtitleColor]="'#384f6b'" [outerStrokeColor]="'#384f6b'"
            [outerStrokeWidth]="15" [startFromZero]="false" [clockwise]="true" [renderOnClick]="false"
            [animationDuration]="1010" style="display: inline-block;">
          </circle-progress>
        </div>
      </div>
    </div>

  </div>
</div>


<div *ngIf="isExerciseFinished && dataSaved"
  style="height: 100vh; width: 100%; top: 0px; position: absolute;z-index:999999">
  <video autoplay muted loop style="width: auto;">
    <source src="assets/videos/congratulations_video.mp4" type="video/mp4">
  </video>

  <app-exercise-result-analysis [user_id]="user.id" [date]="date" [period]="period" [exercise]="raw_exercise"
    [role]="user.role"></app-exercise-result-analysis>
  <span class="custom-btn" (click)="onClose($event)">
    <i class="fas fa-times" style="font-size: 40px; color: #d8f3ff;"></i>
  </span>
</div>