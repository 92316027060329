  <div class="card bg-dark p-2 mb-4 mx-3">
    <div class="card-header">
      <div class="card-title w-100" style="display: inline-flex">
        <div style="width: 40%;">
          <h3 class="text-white" style="font-size: 24px; font-weight: 600;">{{ "patientDetails.performanceOverview.activity" | translate }}</h3>
          <div class="d-flex align-items-center">
            <p class="text-white mb-0 text-nowrap">{{ "patientDetails.performanceOverview.stepGoal" | translate }}: </p>
            <p *ngIf="!stepEdit" class="text-white mb-0 ml-1">{{ stepGoal }}</p>
            <input *ngIf="stepEdit" [(ngModel)]="stepGoalCopy" type="text" style="box-sizing: border-box; max-width: 4rem; padding: 2px 3px; line-height: 0px; border-radius: 0.5rem; border: 0; box-shadow: none;" class="ml-1 mr-1" />
            <div *ngIf="!stepEdit" class="d-flex justify-content-center align-items-center rounded ml-3" style="padding: 0.2rem; cursor: pointer; margin-bottom: 0.2rem;" (click)="editStepGoal()">
              <i class="fas fa-edit" style="color: white; pointer-events: none;"></i>
            </div>
            <div *ngIf="stepEdit" class="d-flex justify-content-center align-items-center rounded ml-1" style="padding: 0.2rem; cursor: pointer; margin-bottom: 0.1rem;" (click)="saveStepEdit()">
              <i class="fas fa-save" style="color: white; pointer-events: none;"></i>
            </div>
            <div *ngIf="stepEdit" class="d-flex justify-content-center align-items-center rounded ml-1" style="padding: 0.2rem; cursor: pointer; margin-bottom: 0.1rem;" (click)="cancelStepEdit()">
              <i class="fas fa-times" style="color: white; pointer-events: none;"></i>
            </div>
          </div>
          <p class="text-white mb-0">{{ "patientDetails.performanceOverview.average" | translate }} {{ "patientDetails.performanceOverview.steps" | translate }}: {{ averageWeeklySteps }}</p>
        </div>
        <div style="width: 60%;">
          <div class="float-right">
            <div>
              <button class="btn p-2" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDateActivity"
                      (viewDateChange)="closeOpenMonthViewDay()" (click)="changeViewClickActivity();">
                  <span>
                    <img src="assets/images/icons/left_angle.svg" alt="Image placeholder" style="height: 30px; width: 30px;"/>
                  </span>
              </button>
              <button class="btn btn-secondary btn-xs rounded-pill" mwlCalendarToday [(viewDate)]="viewDateActivity" (click)="changeViewClickActivity();">
                {{ 'generalTerms.today' | translate }}
              </button>
              <button class="btn p-2" mwlCalendarNextView [view]="view" [(viewDate)]="viewDateActivity"
                      (viewDateChange)="closeOpenMonthViewDay()" (click)="changeViewClickActivity();">
                  <span>
                    <img class="my-auto" src="assets/images/icons/right_angle.svg" alt="Image placeholder" style="height: 30px; width: 30px;"/>
                  </span>
              </button>
            </div>
            <p class="text-white text-center">{{ viewDateActivity | calendarDate:(view + 'ViewTitle'):language }}</p>
          </div>
          <div *ngIf="devices && devices.length > 1" ngbDropdown  style="margin-left: 38px;padding-top:8px;">
            <button
              class="btn btn-xs btn-secondary"
              id="dropdownMenuButton"
              ngbDropdownToggle
            >
              {{ "patientDetails.performanceOverview.device" | translate }} {{selectedDevice}}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton" style="max-height: 12rem; overflow-y: auto;">
              <button
                ngbDropdownItem
                *ngFor="let device of devices, index as i"
                (click)="selectedDevice = i + 1; changeDevice();"
              >
               {{ "patientDetails.performanceOverview.device" | translate }} {{i + 1}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="dataLoadedWeekly">
      <app-measurement-chart [plotData]="weeklySteps" [type]="'steps'"></app-measurement-chart>
    </div>
    <div *ngIf="!dataLoadedWeekly" class="centered preloader d-flex justify-content-center align-items-center pb-5">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <span class="ml-auto mt-4">
      <img src="assets/images/steps.svg" alt="Image placeholder" style="height: 30px; width: 30px; position: absolute; bottom: 10px; right: 10px;"/>
    </span>
  </div>
