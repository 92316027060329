import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { MeasurementService } from '@services/health-data/measurement.service';
import { Http2Service } from '@services/http2.service';
import { forkJoin } from 'rxjs';
import { IUserProfile } from 'src/app/resolvers';

@Component({
  selector: 'app-health-tab',
  templateUrl: './health-tab.component.html',
  styleUrls: ['./health-tab.component.css'],
})
export class HealthTabComponent implements OnInit, AfterViewInit {
  activeView: string = 'activity';
  activeData = {};
  me: IUserProfile;
  showMetrics: boolean = true;

  locale: any;
  patientId: string;
  patientDetails: any;

  loading: boolean = true;
  private _counter: number = 0;
  measurements: any[] = [];

  isSmallScreen = window.innerWidth < 850;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isSmallScreen = window.innerWidth < 850;
    console.log(this.isSmallScreen);
  }

  constructor(
    private http: Http2Service,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private measurementService: MeasurementService
  ) {
    this.me = this.authService.me;
    this.locale = this.translate.currentLang;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang;
      this.loadData();
    });
  }
  ngAfterViewInit(): void {
    this.route.queryParamMap.subscribe((q) => {
      let view = q?.get('action');
      if (['activity', 'survey-results'].includes(view)) {
        this.setActiveView(view);
      }
    });
  }
  ngOnInit(): void {
    this.patientId = this.route.parent?.snapshot.paramMap.get('id');
    this.loadData();
  }

  loadData(): void {
    if (!this.patientId) return;

    this.loading = true;

    forkJoin({
      patientDetails: this.http.fetch('get_user_details', {
        patient_uid: this.patientId,
      }),
      measurements: this.measurementService.getAllMeasurements(
        this.patientId,
        this.locale
      ),
    }).subscribe(
      ({ patientDetails, measurements }) => {
        this.patientDetails = patientDetails;

        this.measurements = measurements.sort(
          (a, b) => Number(b.active) - Number(a.active)
        );

        const mV = this.measurements.find((m) => this.activeView == m._id);
        if (mV) {
          this.setActiveData(mV);
        }

        this.loading = false;
      },
      (error) => {
        console.error('Error:', error);
        this.loading = false;
      }
    );
  }

  setActiveView(view: string): void {
    this.activeView = view;
    this.router.navigate([], {
      queryParams: { action: view },
      queryParamsHandling: 'merge',
    });
  }

  setActiveData(data: any) {
    this.activeData = data;
  }

  IncreaseCounter() {
    this._counter++;
    if (this._counter == 1) {
      this.loading = false;
      this._counter = 0;
    }
  }
}
