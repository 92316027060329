import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';


interface FilterMatrix {
  type: string;
  values: number[];
}

interface Filter {
  id: string;
  matrices: FilterMatrix[];
}

@Component({
  selector: 'app-filtered-image',
  templateUrl: './filtered-image.component.html',
  styleUrls: ['./filtered-image.component.css'],

})
export class FilteredImageComponent {
  @Input() filterName: string = 'none';
  @Input() imageUrl: string | undefined | null = undefined;
  @Input() isVertical: boolean = true



}
