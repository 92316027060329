import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { GeneralService } from '@services/general/general.service';
import { VideoService } from '@services/video.service';
import { IUserProfile } from 'src/app/resolvers';
import { AIClient, AudioManager, CounterExercise, DoubleCounterExercise, Exercise, MaxEffortExercise, SingleCounterExercise } from 'exercise_engine';
import { Config, getExerciseType } from 'exercise_engine';

import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { AudioPlayer } from '@helpers/sound-player';
import { ExerciseService } from '@services/exercise/exercise.service';
import beeps from "src/assets/audios/beep_audio.json";
import { ExercisePauseResumeCloseComponent } from '../exercise-pause-resume-close/exercise-pause-resume-close.component';
@Component({
  selector: 'app-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.css']
})
export class ExerciseComponent implements AfterViewInit, OnDestroy {
  closeCameraView() {
    try {
      const videoElement = document.getElementById('cameraVideo') as HTMLVideoElement;
      if (!videoElement) return;
      const tracks = (videoElement.srcObject as MediaStream).getTracks()
      tracks.forEach(track => track.stop());
      videoElement.srcObject = null;

    } catch (error) {
      console.error(error)
    }
  }
  isExerciseFinished: boolean = false;
  dataSaved: boolean = false;
  videoDimensions: { width: number; height: number };
  async saveExerciseData(lastState: any) {
    //console.log('this.raw_exercise', this.raw_exercise, this.user)

    this.aiClient.getSessionData().then(data => {
      data.patient_uid = this.user.id
      data.ai_enabled = this.exercise.isAiActive()
      data.ai_disabled_reason = lastState.disabled_reason
      data.sets = this.raw_exercise.sets
      data.date = this.date
      data.period = this.raw_exercise.period
      data.exercise_id = this.raw_exercise.exercise_id
      if (['breathing'].includes(this.raw_exercise.category)) {
        data.inhale_duration = this.raw_exercise.actions[0].duration;
        data.hold_duration = this.raw_exercise.actions[1].duration;
        data.exhale_duration = this.raw_exercise.actions[2].duration;
      } else {
        try {
          delete data.inhale_duration
          delete data.hold_duration
          delete data.exhale_duration
        } catch (error) {

        }
      }
      //console.log('saveExerciseData////', { data })

      if (this.user.role != 'patient') {
        this.dataSaved = true
        return;
      }
      this.exerciseService.uploadResults(data).subscribe(response => {
        //console.log('flush_session>>', { response })
      }, error => {

      }, () => {
        //   //this.getExerciseResults();
        this.dataSaved = true
      });
    })
  }

  @ViewChild('cameraVideo', { static: false }) camera_elem: ElementRef<HTMLVideoElement>;
  @ViewChild('relaxationVideos', { static: false }) relaxationVideos: ElementRef<HTMLVideoElement>;
  @ViewChild('exercise_video_elem', { static: false }) exerciseVideoElem!: ElementRef<HTMLVideoElement>;
  @ViewChild('overlayContainer') overlayContainer!: ElementRef<HTMLImageElement>;



  @Output() onExerciseDone: EventEmitter<any> = new EventEmitter();
  @Input() raw_exercise: any;
  @Input() date: string;
  @Input() period: number;
  shouldBlink: boolean = false
  videoLoaded: boolean = false;
  show_camera: boolean = false;
  videoUri: string;
  //resizeObserver: ResizeObserver;
  user: IUserProfile;
  language: string;
  show_exercise_video: boolean;
  private _step: 'loading-resources' | 'ready' | undefined = undefined;
  set step(val) {
    this._step = val
    if (val == 'ready') {
      setTimeout(() => {
        this.openCamera()
        setTimeout(() => {
          this.exercise.start()
        }, 1000)
        // if (!this.isRelaxationExercise)
        //   this.openCamera()
        // else {
        //   this.exercise.start()
        // }
      }, 1000)
    }
  }
  get step() {
    return this._step
  }
  get isRelaxationExercise() {
    return this.raw_exercise?.name == "long_term"
  }
  all_warnings: any;
  checkpoints_images: any = {};
  initial_pose_image: string;
  audios = new Map<string, string>();
  aiClient!: AIClient;
  exercise_finished: boolean;
  show_count_down: boolean = false
  show_small_count_down: boolean = false
  count_down_value: number = 5
  silhouette_image: string | null = null;
  silhouette_class: string | null = null;
  exercise: Exercise;
  countdownPercentage: number;
  videoDisplayWidth: number;
  videoDisplayHeight: number;
  imgDisplayedHeight: number;
  imgDisplayedWidth: number;
  leftCameraPosistion: number = 0;
  showSpinner: boolean = false;
  currentExerciseState: any = {}
  silhouatteLeftPosition: string;
  smallCountdownPosition: {
    top: string;
    left: string;
  } = { top: '0px', left: '0px' }
  bigCountdownPosition: {
    top: string;
    left: string;
  } = { top: '0px', left: '0px' }

  countersData: {
    currentCounterValue?: number | undefined
    totalCounterValue: number
    valueName: string
    leftValue?: number | undefined
    rightValue?: number | undefined
    rightValueName?: string
    leftValueName?: string
  } = {
      currentCounterValue: 0, totalCounterValue: 0, valueName: 'total',
      leftValue: 0,
      rightValue: 0,
      rightValueName: 'right',
      leftValueName: 'left',
    }
  constructor(private router: Router,
    private renderer: Renderer2,
    private videoService: VideoService,
    private authService: AuthService,
    private exerciseService: ExerciseService,
    private ts: TranslateService, private generalService: GeneralService,) {
    this.user = this.authService.me
    this.language = this.ts.currentLang;
    this.ts.onLangChange.subscribe(value => {
      this.ts.use(value.lang);
      this.language = value.lang;
      //this.getAudioWarningTranslations();
    });

  }
  get initializing() {
    return this.step == undefined || this.step == 'loading-resources'
  }
  get isExerciseReady() {
    return this.step == 'ready'
  }
  onClose($evt) {
    this.generalService.stop();
    try {
      const videoElement = document.getElementById('cameraVideo') as HTMLVideoElement;
      if (videoElement) {
        const tracks = (videoElement.srcObject as MediaStream).getTracks()
        tracks.forEach(track => track.stop());
        videoElement.srcObject = null;
      }

    } catch (error) {
      console.error(error)
    }
    this.exercise.close()
    this.onExerciseDone.emit(null)
    this.goBack()
  }
  goBack(): void {
    if (this.user.role == "physiotherapist")
      this.router.navigate(['/dashboard'])
  }
  get isVerticalExercise() {
    return this.exercise.isVertical()
  }

  async fetchAudioWithRetry(text) {
    try {
      const [key, base64] = await this.text2audio(text);
      return base64;
    } catch (error) {
      console.error(`Error processing "${text}":`, error);
      return null; // Return null for errors
    }
  };
  audioGetterFn(data: any) {
    return this.fetchAudioWithRetry(data.warning_text)
  }

  async initializeExercise(): Promise<any> {
    //console.log(this.date)
    this.showSpinner = true
    this.step = 'loading-resources'
    const e = await this.prepaireExercise()
    if (!e) return;

    const cfg: Config = {
      activePoseTimeout: 65 * 1000,
      inactivePoseTimeout: 15 * 1000,//when ai is disabled just wait 15s before starting the count-down
      startGestureTimeout: 8 * 1000,
      initialPoseTimeout: 8 * 1000,//play the warning when this duration passed

    }
    const audioPlayer: AudioPlayer = new AudioPlayer("mp3AudioPlayer")
    const audioManager = new AudioManager();
    //console.log('this.audios.keys.length = ', this.audios.keys.length)
    audioManager.setAudios(this.audios)
    audioManager.setAudioPlayer(audioPlayer)


    audioManager.setAudioLoader(this.audioGetterFn.bind(this))

    this.aiClient = new AIClient(environment.baseSamuraiUrl)
    this.aiClient.connect()
    this.aiClient.setAllWarnings(this.all_warnings)
    this.aiClient.setWarningIdGenerator((text: string) => { return this.hashStringDJB2(text).toString() })
    this.aiClient.setUserId(this.user.id)
    this.aiClient.setExercise(this.raw_exercise)

    //this.exercise = new SingleCounterExercise(cfg, audioManager, e);
    const _class = getExerciseType(e.name)
    this.exercise = new _class(cfg, audioManager, e);
    this.exercise.attachAIClient(this.aiClient)
    this.exercise.addObserver(this.handleStateUpdate)
    this.showSpinner = false
    this.step = 'ready'
    return new Promise((resolve, reject) => {
      resolve(this.exercise)
    })

  }
  get isSingleCounter() {
    return this.exercise instanceof SingleCounterExercise || this.exercise instanceof CounterExercise
      || this.exercise instanceof MaxEffortExercise
  }
  get isDoubleCounter() {
    return this.exercise instanceof DoubleCounterExercise
  }
  get count_type() {
    return ['sit2stand_test', 'sit2stand_duration'].includes(this.raw_exercise.name) ? 'up' : 'down'
  }
  calculatePercentage = (currentValue: number, maxValue: number): number => {
    if (currentValue < 0) return 0
    return (currentValue / maxValue) * 100;
  };
  cc = 0
  handleStateUpdate = (prevState, state) => {

    //console.log('state', state )
    this.currentExerciseState = { ...state }
    this.exercise_finished = state.exercise_finished
    if (state.exercise_finished === true) {
      this.closeCameraView()
      this.isExerciseFinished = state.exercise_finished === true
    }

    if (state.exercise_finished) {
      this.exercise.removeObserver(this.handleStateUpdate)
      this.saveExerciseData(state)

    }
    this.aiClient.updateState(this.currentExerciseState)

    this.show_count_down = state.start_count_down
    this.show_small_count_down = state.start_small_count_down


    this.countersData.totalCounterValue = state.targetValue
    if (this.isSingleCounter) {
      this.countersData.currentCounterValue = state.counterValue
    }
    if (this.isDoubleCounter) {
      this.countersData.leftValue = state.leftCounterValue
      this.countersData.rightValue = state.rightCounterValue
    }

    if (state.start_count_down) {
      //console.log('A',{state})
      this.silhouette_image = null
      this.countdownPercentage = this.calculatePercentage(state.count_down_value, state.count_down_max);
      this.count_down_value = state.count_down_value
    }
    if (state.start_small_count_down) {

      //console.log('a',{state})
      this.countdownPercentage = this.calculatePercentage(state.count_down_value, state.count_down_max);
      this.count_down_value = state.count_down_value
    }
    if (state?.aiResponse?.warning) {
      //console.log(state?.aiResponse?.warning)
    } else {
      //console.log(null)
    }

    if (state?.action?.checkpoint_name) {
      this.silhouette_image = this.checkpoints_images[state?.action?.checkpoint_name]
      //      setTimeout(() => { this.adjustOverlaySize() })

    }
    if (state.hide_silhouette || (state?.action && !state?.action?.checkpoint_name)) {
      this.silhouette_image = null
    }
    if (state.silhouette_class) {
      this.silhouette_class = state.silhouette_class
    } else this.silhouette_class = null

    if (state.render_videos) {
      if (!this.videosLoopStarted) {
        this.videosLoopStarted = true
        const renderer = this.getVideoRenderer(state.action.videos)
        renderer()
      }

    }
  }
  videosLoopStarted: boolean = false
  ngAfterViewInit(): void {




    this.playExerciseVideo().then(founded => {
      this.show_exercise_video = founded
      if (!founded) {
        this.initializeExercise()
      } else {
        setTimeout(() => {
          const video: HTMLVideoElement = this.exerciseVideoElem?.nativeElement;
          if (!video) return

          video.addEventListener('loadeddata', () => {
            this.videoLoaded = true
          });
          video.addEventListener('ended', () => {
            this.show_exercise_video = false
            this.onVideoNextButtonClicked()
          });
        }, 400)
      }
    })
  }
  onVideoSkipped() {
    const video: HTMLVideoElement = this.exerciseVideoElem?.nativeElement;
    if (video)
      video.pause()
    this.show_exercise_video = false
    this.onVideoNextButtonClicked()
  }
  onVideoClose() { }
  async playExerciseVideo(): Promise<boolean> {
    const getVideo = async (directory: string, file: string): Promise<string | null> => {
      return new Promise((resolve) => {
        this.videoService
          .getInstructionVideo(directory, this.language, file)
          .subscribe(
            (url) => {
              resolve(url)
            },
            (error) => {
              if (error.code === 'storage/object-not-found') {
                resolve(null)
              } else {
                resolve(null)
              }
            }
          );
      })
    }
    let directory = this.user.role === 'physiotherapist' ? this.user.id : this.user.physiotherapist._id
    const getVideoFileName = () => {
      if (this.raw_exercise.name == 'long_term') return `${this.raw_exercise.name}_breathing.mp4`
      return `${this.raw_exercise.name}.mp4`
    }
    let url = await getVideo(directory, getVideoFileName())
    if (!url) {
      directory = 'breathment'
      url = await getVideo(directory, getVideoFileName())
    }
    if (url) {
      this.videoUri = url
      return true
    }
    return false
  }

  onVideoNextButtonClicked() {
    const video: HTMLVideoElement = this.exerciseVideoElem.nativeElement;
    video.pause();        // Pause the video
    video.currentTime = 0; // Reset the video to the beginning
    this.show_exercise_video = false
    this.initializeExercise()
  }
  controlButtonsStyle: { width: string, top: string, position: string, height: string }
  timersContainerStyle: { width: string, top: string, position: string, height: string }
  updatePauseResumeCloseCompPos(w: string, l: string, t: string) {
    this.controlButtonsStyle = {
      //left: l,
      top: t,
      width: w,
      position: 'absolute',
      height: '30px'
    }
    this.timersContainerStyle = {
      top: t,
      width: w,
      position: 'absolute',
      height: '100%'
    }
    if (this.isRelaxationExercise && this.videosLoopStarted) this.camera_elem.nativeElement.style.width = w
  }
  private adjustOverlaySize(): void {
    const video = this.getReferanceVideoElement()
    if (!video) return

    const overlay = this.overlayContainer.nativeElement;

    const videoAspectRatio = video.videoWidth / video.videoHeight;
    const containerAspectRatio = video.clientWidth / video.clientHeight;

    //calculate the container height and width based on the aspect ratio of the video
    let containerWidth, containerHeight;
    if (videoAspectRatio > containerAspectRatio) {
      containerWidth = video.clientWidth;
      containerHeight = video.clientWidth / videoAspectRatio;
    } else {
      containerWidth = video.clientHeight * videoAspectRatio;
      containerHeight = video.clientHeight;
    }
    // overlay.style.width = containerWidth + 'px';
    overlay.style.height = containerHeight + 'px';
    // overlay.style.left = (video.clientWidth - containerWidth) / 2 + 'px';
    // overlay.style.top = (video.clientHeight - containerHeight) / 2 + 'px';
    //    if (!this.videosLoopStarted)
    this.updatePauseResumeCloseCompPos(`${containerWidth}px`, `${(video.clientWidth - containerWidth) / 2}px`, `${(video.clientHeight - containerHeight) / 2}px`)

  }
  getReferanceVideoElement() {
    if (this.isRelaxationExercise && this.videosLoopStarted) {
      return this.relaxationVideos.nativeElement
    }
    return this.camera_elem.nativeElement
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.adjustOverlaySize();
    //this.adjustControlButtonsPosition()
  }


  getVideoRenderer(videos: string[]) {
    let videoIndex = 1
    let started: boolean = false
    return () => {

      if (started || videos.length < 0) return
      started = true

      this.relaxationVideos.nativeElement.src = videos[videoIndex];

      this.renderer.listen(this.relaxationVideos.nativeElement, 'loadedmetadata', () => {
        this.adjustOverlaySize();
        //this.adjustControlButtonsPosition()
      });

      // Also update the size when the window is resized
      //this.adjustOverlaySize();

      this.relaxationVideos.nativeElement.addEventListener('canplay', () => {
        this.show_camera = true
      })
      this.relaxationVideos.nativeElement.addEventListener('play', () => {

      });
      this.relaxationVideos.nativeElement.addEventListener('ended', () => {
        videoIndex = videoIndex < videos.length - 1 ? videoIndex++ : videoIndex = 0;
        this.relaxationVideos.nativeElement.src = videos[videoIndex];

      });
    }

  }
  // adjustControlButtonsPosition() {
  //   if (!this.isRelaxationExercise) return
  //   const { w, l, t } = this.getVideoDimAndPos()
  //   this.updatePauseResumeCloseCompPos(`${w}px`, `${l}px`, `${t}px`)
  // }
  openCamera() {
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {

          this.camera_elem.nativeElement.srcObject = stream;

          this.renderer.listen(this.camera_elem.nativeElement, 'loadedmetadata', () => {
            this.adjustOverlaySize();
          });

          // Also update the size when the window is resized
          this.adjustOverlaySize();

          this.camera_elem.nativeElement.addEventListener('loadedmetadata', () => {

            this.adjustOverlaySize();
          })
          this.camera_elem.nativeElement.addEventListener('canplay', () => {
            this.show_camera = true
            this.adjustOverlaySize();
          })
          this.camera_elem.nativeElement.addEventListener('play', () => {
            //this.setupResizeObserver()
            this.processFrames(this.camera_elem.nativeElement)
            //this.exercise.start()
          });
        })
        .catch((e) => console.error(e));
    }
  }
  showSilhouatte() {

    return (this.silhouette_image && this.show_camera);//|| (this.isRelaxationExercise && !this.videosLoopStarted)
  }
  async prepaireExercise(): Promise<boolean | any> {
    const loaded = await this.loadWarningsText();
    if (!loaded) return false;

    const imagesLoadedSuccessfully = await this.loadCheckpointsImages();
    if (!imagesLoadedSuccessfully) return false;
    const initialPoseImageLoaded = await this.loadInitialPoseImageUrl()
    if (!initialPoseImageLoaded) return false;

    this.silhouette_image = initialPoseImageLoaded




    await this.loadWarningsAudio()
    const actions = await this.loadInstructionsAudio()
    //actions[0].duration = 500 * 1000
    const ex = this.raw_exercise;
    //console.log({ ex })
    const obj: any = {
      id: ex._id,
      reps: ex.repetitions,
      sets: ex.sets,
      name: ex.name,
      display_name: ex.display_name[this.language],
      category: ex.category,
      description: ex.description[this.language],
      is_vertical: ex.vertical,
      ai_state: ex.initial_pose.check == true ? 'active' : 'inactive',
      initial_pose_margin: ex.initial_pose.margin,
      initial_pose: ex.initial_pose.pose,

      set: {
        actions,
        set_break: {
          active: true,
          duration: ex.set_break.duration * 1000,
          text: ex.set_break.instruction[this.language],
          audio_key: ''
        }
      }
    }
    try {
      const [key, base64] = await this.text2audio(obj.set.set_break.text)
      this.audios.set(key, base64)
      obj.set.set_break.audio_key = key
    } catch (error) {

    }

    return obj;
  }
  async loadWarningsAudio() {

    const fetchAudioWithRetry = async (text) => {
      try {
        const [key, base64] = [this.hashStringDJB2(text), '']
        return [key, base64];
      } catch (error) {
        console.error(`Error processing "${text}":`, error);
        return null; // Return null for errors
      }
    };
    let i = 0;
    let m = new Map<string, any>();
    const promises = Object.entries(this.all_warnings).map(([key, value]) => {


      if (typeof value === 'string') {
        if (m.get(value)) {
          return Promise.resolve(null);
        }
        m.set(value, true)
        return fetchAudioWithRetry(value)
          .then(result => result || fetchAudioWithRetry(value)); // Retry if result is null
      }

      return Promise.resolve(null); // Return a resolved promise for non-string entries
    });

    m.clear()
    // Wait for all promises to resolve and filter out null values
    const results = await Promise.all(promises);
    this.audios.set('beep', beeps.beep_audio)
    this.audios.set('longbeep', beeps.long_beep_audio)
    this.audios.set('successbeep', beeps.success_beep_audio)
    const filteredResults = results.filter(result => result !== null);

    for (const result of filteredResults) {
      if (result) {
        try {
          const [audioKey, base64] = result;
          if (audioKey)
            this.audios.set(audioKey, base64);
        } catch (error) {
          console.error('EEE::::', error)
        }
      }
    }

    const generic_messages = [
      { key: 'autopilot_message' },
      { key: 'silhouettePosition' },
      { key: 'autopilot_message_when_ai_is_already_disabled' },
      { key: 'raiseArmToStart' },
      { key: 'nextSet' },

    ]
    for (let i = 0; i < generic_messages.length; i++) {
      const text = this.all_warnings[generic_messages[i].key]
      try {
        let [_, base64] = await this.text2audio(text)
        generic_messages[i].key = generic_messages[i].key

        this.audios.set(generic_messages[i].key, base64)
      } catch (error) {

      }
    }
  }


  async loadInstructionsAudio() {
    const actions = await Promise.all(
      this.raw_exercise.actions.map(async (a) => {
        const obj: any = {
          name: a.name,
          audio_key: null,
          text: a.instruction[this.language],
          checkpoint: a.checkpoint,
          checkpoint_name: a.silhouette_name,
          play_countdown_after_audio_ends: a.play_countdown_after_audio_ends || false,
          count: a.count,
          duration: a.duration * 1000,
          timeout: 6 * 1000
        };
        try {
          const [key, base64] = await this.text2audio(a.instruction[this.language]);
          this.audios.set(key.toString(), base64);
          obj.audio_key = key
        } catch (error) {
          console.error(`Failed to load audio for action: ${a.name}`, error);
        }
        if (a.background_video) {
          obj.videos = await this.getLongTermVideos(a.background_video)
        }
        return obj
      })
    );

    return actions;
  }
  async getLongTermVideos(names: string[]): Promise<string[]> {
    let links: string[] = []
    for (let i = 0; i < names.length; i++) {
      const url = await this.generalService.getFirebaseResourceUrl(`${names[i]}.mp4`)
      if (url) {
        links.push(url)
      }
    }
    return links
  }
  async loadInitialPoseImageUrl(): Promise<string | null> {
    return new Promise((resolve) => {

      this.generalService.getFirebaseResourceUrl(`design/silhouettes/initial-pose/${this.raw_exercise.name}_${this.raw_exercise.initial_pose.pose}.png`)
        .then(url => {
          if (!url)
            return resolve(null)
          this.initial_pose_image = url
          const img = new Image();
          img.src = url;
          // Resolve true if the image loads successfully
          img.onload = () => resolve(url);
          // Resolve false if the image fails to load
          img.onerror = () => resolve(null);

        })
    })
  }
  hashStringDJB2(str: string): string {
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
      hash = (hash * 33) ^ str.charCodeAt(i);
    }
    return 'KEY_' + (hash >>> 0);
  }
  async text2audio(text: string): Promise<[string, string]> {
    return new Promise((resolve, reject) => {
      this.generalService.getTextAudio({ warning: text, language: this.language })
        .subscribe(
          (resp) => {
            resolve([this.hashStringDJB2(text), resp['data']]);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  get isPaused() {
    return this.currentExerciseState?.paused == true
  }
  pauseOrResume(pause: boolean) {
    if (pause) {
      this.exercise.pause()
      if (this.isRelaxationExercise) {
        const videoElementRef = this.camera_elem.nativeElement;
        const relaxationVideosRef = this.relaxationVideos.nativeElement;
        videoElementRef.pause()
        relaxationVideosRef.pause()
      }
    }
    else {
      this.exercise.resume()
      if (this.isRelaxationExercise) {
        const cameraRef = this.camera_elem.nativeElement;
        cameraRef.play()
        const relaxationVideosRef = this.relaxationVideos.nativeElement;
        relaxationVideosRef.play()
      }
    }
  }
  async loadCheckpointsImages(): Promise<boolean> {
    const imageLoadPromises: Promise<boolean>[] = [];
    this.raw_exercise.actions.forEach((action, i) => {
      if (this.raw_exercise.category == 'breathing_time' && i == 1) {
        action.silhouette_name = this.raw_exercise.actions[0].silhouette_name
      }
      if (action.silhouette_name) {
        const name = this.raw_exercise.name + '-' + action.silhouette_name;
        const loadImagePromise = new Promise<boolean>((resolve) => {
          this.generalService.getCheckpointSilhouette(name).subscribe((url) => {
            this.checkpoints_images[action.silhouette_name] = url;

            const img = new Image();
            img.src = url;

            // Resolve true if the image loads successfully
            img.onload = () => resolve(true);

            // Resolve false if the image fails to load
            img.onerror = () => resolve(false);
          });
        });

        imageLoadPromises.push(loadImagePromise);
      }
    });

    // Wait for all image load promises to complete and check if any failed
    const results = await Promise.all(imageLoadPromises);

    // Return true if all images loaded successfully, false otherwise
    return results.every(result => result === true);
  }
  async loadWarningsText(): Promise<boolean> {
    return new Promise((resolve) => {
      this.generalService.getAudioTranslations({ lang: this.language }).subscribe((res) => {
        this.all_warnings = res?.data?.audioWarnings;
        this.all_warnings = {
          ...this.all_warnings,
          ...this.all_warnings.outer_space,
          ...this.all_warnings.ext_shoulder_rot,
          ...this.all_warnings.cosmic
        }
        delete this.all_warnings.outer_space
        delete this.all_warnings.ext_shoulder_rot
        delete this.all_warnings.cosmic

        resolve(true)
      },
        (err) => {
          resolve(false)
        })
    })
  }

  ngOnDestroy() {
    //this.cleanupResizeObserver();
  }
  processFrames(videoElement: HTMLVideoElement) {
    // Create an offscreen canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set the canvas dimensions to match the video element
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;

    // Define a variable for the frame rate (5 frames per second)
    const fps = 2;
    const frameInterval = 1000 / fps; // Time between frames (in milliseconds)

    // Function to capture frames at a set interval (5 fps)
    const captureFrame = () => {

      if (videoElement.paused || videoElement.ended) {
        return; // Stop capturing if the video is paused or ended
      }

      // Draw the current video frame on the canvas
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      // Convert the canvas content to Base64 (PNG format by default)
      const base64Image = canvas.toDataURL('image/jpeg', 0.7);

      // Log or process the Base64 image

      this.aiClient.sendFrame({
        uid: this.user.id,
        image: base64Image.substring(23),
        category: this.raw_exercise.category,
        name: this.raw_exercise.name
      })
      // Schedule the next frame capture
      if (!this.exercise_finished)
        setTimeout(captureFrame, frameInterval); // Capture every 200ms for 5 fps
      else {

      }

    }

    // Start capturing the frames
    if (!this.exercise_finished)
      captureFrame();
    else {

    }
  }


}