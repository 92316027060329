import { Component, Input, SimpleChanges, OnInit } from '@angular/core';
import { MeasurementService } from '@services/health-data/measurement.service';

@Component({
  selector: 'app-measurement-container',
  templateUrl: './measurement-container.component.html',
  styleUrls: ['./measurement-container.component.css'],
})
export class MeasurementContainerComponent implements OnInit {
  @Input() patientId: string;
  @Input() measurementId: string;
  @Input() measurementName: string;
  @Input() measurementUnit: string;

  periods = [
    { key: 'D', label: 'daily' },
    { key: 'W', label: 'weekly' },
    { key: 'M', label: 'monthly' },
    { key: 'Y', label: 'yearly' },
    { key: 'Custom Range', label: 'custom' },
  ];
  selectedPeriod = 'W';
  dateRange = '';
  startDate: Date;
  endDate: Date;
  flowData: { value: number | null; date: Date }[] = [];
  averageFlow = 0;
  flowDataLoaded = false;

  constructor(private measurementService: MeasurementService) {}

  ngOnInit() {
    this.updateDateRange();
    this.fetchMeasurements();
  }

  getDateRange(period: string) {
    this.flowDataLoaded = false;
    let startDate = new Date();
    let endDate = new Date();

    switch (period) {
      case 'W':
        startDate.setDate(startDate.getDate() - startDate.getDay() + 1);
        endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);
        break;
      case 'M':
        startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
        endDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 1,
          0
        );
        break;
      case '6M':
        startDate.setMonth(startDate.getMonth() - 6);
        break;
      case 'Y':
        startDate = new Date(startDate.getFullYear(), 0, 1);
        endDate = new Date(startDate.getFullYear(), 11, 31);

        break;
    }

    return {
      start_date: this.formatDate(startDate),
      end_date: this.formatDate(endDate),
    };
  }

  updateDateRange() {
    const { start_date, end_date } = this.getDateRange(this.selectedPeriod);
    this.startDate = this.parseDate(start_date);
    this.endDate = this.parseDate(end_date);

    switch (this.selectedPeriod) {
      case 'D':
        this.dateRange = start_date;
        break;
      case 'Y':
        this.dateRange = `${this.endDate.getFullYear()}`;
        break;
      default:
        this.dateRange = `${start_date} - ${end_date}`;
    }
  }

  fetchMeasurements() {
    this.measurementService
      .getAllMeasurementsByRange(
        this.measurementId,
        this.formatDate(this.startDate),
        this.formatDate(this.endDate),
        this.patientId,
        'D'
      )
      .subscribe((response: any) => {
        if (response) {
          this.flowData = response.map((entry) => ({
            value: entry.value,
            date: new Date(entry.date),
          }));
          this.calculateAverageFlow();
          this.flowDataLoaded = true;
        }
      });
  }

  calculateAverageFlow() {
    const validValues = this.flowData
      .filter((entry) => entry.value !== null)
      .map((entry) => entry.value!);
    this.averageFlow = validValues.length
      ? parseFloat(
          (
            validValues.reduce((acc, val) => acc + val, 0) / validValues.length
          ).toFixed(2)
        )
      : 0;
  }

  prevPeriod() {
    this.adjustDateRange(-1);
  }

  nextPeriod() {
    this.adjustDateRange(1);
  }

  adjustDateRange(step: number) {
    switch (this.selectedPeriod) {
      case 'D':
        this.startDate.setDate(this.startDate.getDate() + step);
        this.endDate = new Date(this.startDate);
        this.dateRange = this.formatDate(this.startDate);
        break;

      case 'W':
        this.startDate.setDate(this.startDate.getDate() + step * 7);
        this.endDate.setDate(this.endDate.getDate() + step * 7);
        this.dateRange = `${this.formatDate(
          this.startDate
        )} - ${this.formatDate(this.endDate)}`;
        break;

      case 'M':
        this.startDate.setMonth(this.startDate.getMonth() + step);
        this.startDate.setDate(1);
        this.endDate = new Date(
          this.startDate.getFullYear(),
          this.startDate.getMonth() + 1,
          0
        );
        this.dateRange = `${this.formatDate(
          this.startDate
        )} - ${this.formatDate(this.endDate)}`;
        break;

      case '6M':
        this.startDate.setMonth(this.startDate.getMonth() + step * 6);
        this.dateRange = `${this.formatDate(
          this.startDate
        )} - ${this.formatDate(this.endDate)}`;
        break;

      case 'Y':
        const newYear = this.startDate.getFullYear() + step;
        this.startDate = new Date(newYear, 0, 1);
        this.endDate = new Date(newYear + 1, 0, 0);
        this.dateRange = `${this.endDate.getFullYear()}`;
        break;
    }

    this.fetchMeasurements();
  }

  formatDate(date: Date) {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    return `${dd}.${mm}.${yyyy}`;
  }

  parseDate(dateStr: string): Date {
    const [dd, mm, yyyy] = dateStr.split('.').map(Number);
    return new Date(yyyy, mm - 1, dd);
  }

  setPeriod(period: string) {
    this.selectedPeriod = period;

    if (period === 'Custom Range') {
      const today = new Date();

      this.startDate = new Date(today);
      this.endDate = new Date(today);

      this.updateCustomRangeText();
      return;
    }

    this.updateDateRange();
    this.fetchMeasurements();
  }

  onCustomStartDateChange(value: string) {
    const date = new Date(value);
    if (!isNaN(date.getTime())) {
      this.startDate = date;
      this.updateCustomRangeText();
    }
  }

  onCustomEndDateChange(value: string) {
    const date = new Date(value);
    if (!isNaN(date.getTime())) {
      this.endDate = date;
      this.updateCustomRangeText();
    }
  }

  updateCustomRangeText() {
    if (this.selectedPeriod === 'Custom Range') {
      this.dateRange = `${this.formatDate(this.startDate)} - ${this.formatDate(
        this.endDate
      )}`;
    }
  }
}
