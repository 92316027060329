import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-exercise-pause-resume-close',
  templateUrl: './exercise-pause-resume-close.component.html',
  styleUrls: ['./exercise-pause-resume-close.component.css']
})
export class ExercisePauseResumeCloseComponent {
  @Output() onCloseEvent = new EventEmitter<any>();
  @Output() onPauseResumeEvent = new EventEmitter<any>();
  @ViewChild('container', { static: true }) containerRef!: ElementRef;

  private _paused: boolean = false
  get isPaused() {
    return this._paused
  }
  set isPaused(val: boolean) {
    this._paused = val
  }
  handleCloseClick() {
    this.onCloseEvent.emit()
  }
  handlePauseResumeClick() {
    this.isPaused = !this._paused
    this.onPauseResumeEvent.emit(this.isPaused)
  }
  getNativeElement(): HTMLElement {
    return this.containerRef.nativeElement;
  }
}
