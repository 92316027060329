import {
  Component,
  EventEmitter,
  OnInit,
  Output, ViewChild,
  TemplateRef,
  AfterViewInit
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  ValidationErrors
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from '../../../constants';
import {
  CareDiagnosis,
  PersonalInfoModel,
} from 'src/app/schemas/personalInformation.model';
import { CopdModel } from '../../../schemas/copd.model';
import { OtherModel, ChronicDisease } from '../../../schemas/other.model';
// import { Allgeries, DiseaseHistoryModel, HeartDisease, Other, PreviousIllness, SideDiagnonses } from 'src/app/schemas/diseasHistory.model';
// import { requireCheckboxesToBeCheckedValidator } from 'src/app/shared/custom-validators/requireCheckboxedToBeChecked';
// import { lifeStyleModel, Employment, ActiveSports } from 'src/app/schemas/lifeStyle.model';
// import { nicotineMinimumAmountToBeChecked } from 'src/app/shared/custom-validators/nicotineMinimumAmountToBeChecked';
// import { MedicationsModel, OtherAuxiliaryDevices, OtherMedication } from 'src/app/schemas/medications.model';
// import { requireInhalationToBeChecked } from 'src/app/shared/requireInhalationToBeChecked';
// import { requireMedicationToBeChecked } from 'src/app/shared/custom-validators/requiredMedicationToBeChecked';
// import { requiredAuxiliaryToBeChecked } from 'src/app/shared/custom-validators/requireAuxiliaryToBeChecked';
// import { oxygenDateValidation } from 'src/app/shared/custom-validators/oxygenDateValidation';
// import { oxygenLitreValidation } from 'src/app/shared/custom-validators/oxygenLitreValidation';
// import { BreathingModel, BreathingMovement, BreathingRhythm, BreathSound, CoughSchedule } from 'src/app/schemas/breathing.model';
// import { requireBreathSoundToBeChecked } from 'src/app/shared/custom-validators/requireBreathSoundToBeChecked';
// import { requireBreathRhymeToBeChecked } from 'src/app/shared/custom-validators/requireBreathRhymeToBeChecked';
// import { MusclesModel, StairMeasurements } from 'src/app/schemas/muscles.model';
import { birthdateValidation } from 'src/app/shared/custom-validators/birthdateValidation';
// import { PersonalStoryModel, ChronicDisease, OtherSymptoms, BackPain } from "../../../schemas/personalStory.model";
import { DataService } from '@services/data.service';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { StoreService } from '@services/store.service';
import { transformOnboardingFormData } from '@helpers/helper';

@Component({
  selector: 'app-create-patient',
  templateUrl: './create-patient.component.html',
  styleUrls: ['./create-patient.component.css'],
})
export class CreatePatientComponent implements OnInit, AfterViewInit {
  onboardingForm: any;
  @Output() back: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  // Models
  public personalInfoModel: PersonalInfoModel = new PersonalInfoModel();
  public copdModel: CopdModel = new CopdModel();
  public otherModel: OtherModel = new OtherModel();

  // public diseaseHistoryModel: DiseaseHistoryModel = new DiseaseHistoryModel();
  // public lifeStyleModel: lifeStyleModel = new lifeStyleModel();
  // public medicationModel: MedicationsModel = new MedicationsModel();
  // public breathingModel: BreathingModel = new BreathingModel();
  // public musclesModel: MusclesModel = new MusclesModel();
  // public personalStoryModel: PersonalStoryModel = new PersonalStoryModel();

  // input cards
  public onboardingCardList: any[] = [];
  public activeIndex: number = 0;
  public personalInfoCard: boolean = true;
  public copdCard: boolean = false;
  public otherCard: boolean = false;

  // public diseaseHistory: boolean = false;
  // public diseaseHistoryCard: boolean = false;
  // public lifeStyleCard: boolean = false;
  // public medicalCard: boolean = false;
  // public breathingCard: boolean = false;
  // public muscleStatusCard: boolean = false;
  // public personalStoryCard: boolean = false;

  //activate check for errors
  public checkPersonalInfo: boolean;
  public checkCopd: boolean;
  public checkOther: boolean;

  // public checkDiseaseHistory: boolean;
  // public checkLifeStyle: boolean;
  // public checkMedications: boolean;
  // public checkBreathing: boolean;
  // public checkMuscles: boolean;
  // public checkPersonalStory: boolean;
  public userExists: boolean = false;

  //Form Controls here ....
  personalInformationGroup: UntypedFormGroup;
  copdGroup: UntypedFormGroup;
  otherGroup: UntypedFormGroup;

  public genderOptions: string[] = ['male', 'female', 'diverse'];

  copdYears: number;
  copdMonths: number;
  copMedicalAids: any[];
  copdActions: any[];
  copdSymptoms: any[];
  newCopdSymptom: string = '';
  copdDurationError: any = null;

  otherActivities: any[];
  otherMedicalAids: any[];

  // diseaseHistoryGroup: UntypedFormGroup;
  // lifeStyleGroup: UntypedFormGroup;
  // medicationsGroup: UntypedFormGroup;
  // breathingGroup: UntypedFormGroup;
  // musclesGroup: UntypedFormGroup;
  // personalStoryGroup: UntypedFormGroup;

  //Patterns
  alphabetPattern: string = Constants.alphabetPattern;
  emailPattern: string = Constants.emailPattern;
  phonePattern: string = Constants.phonePattern;

  //screen size
  // public screenWidth: any;
  // public screenHeight: any;

  //Constants
  // inhalationOptions = Constants.inhalationOptions
  // employmentTypeOptions = Constants.employmentTypeOptions
  // activeSportsOptions = Constants.activeSportsOptions

  // Warnings
  // public disableSideDiagnoseNone: boolean = false;
  data: any;
  public locale: string;

  physiotherapist: any = {};
  @ViewChild('createPatientConfirmationModal', { read: TemplateRef })
  createPatientConfirmationModal: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
    private dataService: DataService,
    private storeService: StoreService,
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.locale = this.translate.currentLang;
    this.translate.onLangChange.subscribe((value) => {
      this.translate.use(value.lang);
      this.locale = value.lang;
      if (this.orginalCards) {
        transformOnboardingFormData(JSON.parse(JSON.stringify(this.orginalCards)), this.locale, this.translate).then(cards => {
          this.allCards = cards
        })
      }
    });

    this.onboardingForm = this.authService.me.onboarding_form;
    this.physiotherapist = this.authService.me;

    this.customizeOnboardingForm();

    this.initializePersonalInfoForm();
    this.initializeCopdForm();
    this.initializeOtherForm();

    this.checkPersonalInfo = false;
    this.checkCopd = false;
    this.checkOther = false;
  }
  ngAfterViewInit(): void {
    //setTimeout(() => {

    this.openModal(this.createPatientConfirmationModal, {
      backdrop: 'static', beforeDismiss: () => {
        this.onTermsRejected(); return true;
      }
    });
    //}, 1)
  }
  allCards: any = {};
  activeCardIndex: number = 0;
  firstUnFilledCardIndex: number = 0;
  onTermsAccepted() { }
  onTermsRejected() {
    this.router.navigate(['dashboard/physiotherapist']);
  }
  saving: boolean = false;

  oncancel() { }
  onSomethingChanged(evt) {

  }


  replaceAllSpacesAndSpecialChars(str, replacement = "") {
    // Combine characters to remove: spaces, special characters (except hyphens),
    // control characters, and combining marks.
    const pattern = /[\s\!-\/\:\;\<\=\>\?\@\[\\\]\^\_\`\{\|\}\~\u0000-\u0020\u007F-\u009F\u02DE-\u03FF\u1F00-\u1FFF\u2000-\u206F\u2070-\u2FFF\u3000-\u303F\uFF00-\uFFEF]/g;

    // Ensure hyphens are only removed inside words, not from the beginning or end.
    return str.replace(pattern, (match, index, fullStr) => {
      return (index === 0 || index === fullStr.length - 1) ? match : replacement;
    });
  }
  doSubmit(data) {
    this.createPatient(data)
  }
  changeCard(step: 'next' | 'previous') {
    if (step == 'next') {
      if ((this.activeCardIndex + 1) > this.firstUnFilledCardIndex)
        this.firstUnFilledCardIndex++;
    }

    if (this.activeCardIndex === 0) {
      const userEmail = this.allCards[0].questions[1][1].value;
      this.userService.isEmailActive({ email: userEmail }).subscribe(data => {
        if (data['data']) {
          this.translate.get('toastr.userExists').subscribe((res: string) => {
            this.toastr.error(res);
          });
        } else {
          this.activeCardIndex++
        }
      })
    } else {
      switch (step) {
        case 'next': this.activeCardIndex++; break;
        case 'previous': this.activeCardIndex--; break;
        default: this.activeCardIndex = this.activeCardIndex; break;
      }
    }

  }
  orginalCards: any;
  ngOnInit(): void {
    this.storeService.getOnBoarding( ).subscribe(data => {
      if (!data) return;
      const _cards = data?.cards;

      this.orginalCards = JSON.parse(JSON.stringify(_cards))
      if (_cards?.length == 0) return;
      transformOnboardingFormData(_cards, this.locale, this.translate)
        .then(cards => {
          this.allCards = cards
        })


    })
  }

  createNewPatient(patient) {
    this.storeService.displaySpinner.next(true);

    this.userService.createUser(patient).subscribe(
      (data) => {
        this.storeService.refreshPatientsList();
        this.storeService.displaySpinner.next(false);
        this.router.navigateByUrl('/dashboard/physiotherapist/' + data.data);
      },
      (error) => {
        this.storeService.displaySpinner.next(false);
        this.translate.get('toastr.patientCreationError').subscribe((res: string) => {
          this.toastr.error(res);
        });
        let key = Object.keys(this.onboardingCardList[this.activeIndex])[0];
        this.onboardingCardList[this.activeIndex][key] = false;
        this.activeIndex = 0;
        key = Object.keys(this.onboardingCardList[this.activeIndex])[0];
        this.onboardingCardList[this.activeIndex][key] = true;
        this.userExists = true;
      }
    );
  }

  // CRUD methods

  createPatient(data: any) {
    const personalInfo = {
      contact_reason: data['personal_information.contact_reason'],
      reason_explanation: data['personal_information.reason_explanation'],
      age: data['personal_information.age'],
      phone: data['personal_information.phone'],
      health_insurance: data['personal_information.health_insurance'],
    };

    const patient = {
      display_name: 'patient',
      email: data['personal_information.email'],
      first_name: data['personal_information.first_name'],
      last_name: data['personal_information.last_name'],
      language: this.locale,
      disable_breathment_integration: false,
      gender: data['personal_information.gender'],
    };
    delete data['personal_information.email'];
    delete data['personal_information.first_name']
    delete data['personal_information.last_name']
    delete data['personal_information.gender']
    delete data['personal_information.contact_reason']
    delete data['personal_information.reason_explanation']
    delete data['personal_information.age']
    delete data['personal_information.phone']
    delete data['personal_information.health_insurance']
    patient['personal_information'] = personalInfo;
    patient['other_info'] = { ...data };
    patient['about_copd'] = {};

    this.back.emit(patient);
    this.createNewPatient(patient);

  }

  isPersonalCardCompleted() {
    if (this.personalInformationGroup.valid) {
      this.personalInfoModel.firstName =
        this.personalInformationGroup.get('firstName').value;
      this.personalInfoModel.lastName =
        this.personalInformationGroup.get('lastName').value;
      this.personalInfoModel.contactReason =
        this.personalInformationGroup.get('contactReason').value;
      this.personalInfoModel.reasonExplanation =
        this.personalInformationGroup.get('reasonExplanation').value;
      this.personalInfoModel.age = this.convertBirthdateFormat(
        this.personalInformationGroup.get('age').value
      );
      this.personalInfoModel.email =
        this.personalInformationGroup.get('email').value;
      this.personalInfoModel.phone =
        this.personalInformationGroup.get('phone').value;
      this.personalInfoModel.height =
        this.personalInformationGroup.get('height').value;
      this.personalInfoModel.weight =
        this.personalInformationGroup.get('weight').value;
      this.personalInfoModel.gender =
        this.personalInformationGroup.get('gender').value;
      this.personalInfoModel.smoking =
        this.personalInformationGroup.get('smoking').value;
      this.personalInfoModel.status =
        this.personalInformationGroup.get('status').value;
      this.personalInfoModel.careDiagnosis.diagnosed =
        this.personalInformationGroup.get('careDiagnosis').value;
      this.personalInfoModel.careDiagnosis.degree =
        this.personalInformationGroup.get('careDiagnosisDeg').value;
      return true;
    }
    return false;
  }

  isCopdCardCompleted() {
    if (this.copdGroup.valid) {
      this.copdModel.classification =
        this.copdGroup.get('classification').value;
      this.copdModel.durationInYears =
        this.copdGroup.get('durationYears').value;
      this.copdModel.durationInMonths =
        this.copdGroup.get('durationMonths').value;
      this.copdModel.ongoingTreatment =
        this.copdGroup.get('ongoingTreatment').value;
      this.copdModel.o2Requirement = this.copdGroup.get('oxygen').value;
      return true;
    }
    return false;
  }

  isOtherCardCompleted() {
    if (this.otherGroup.valid) {
      this.otherModel.sensitiveVeins =
        this.otherGroup.get('sensitiveVeins').value;
      this.otherModel.cardiovascularDisease = this.otherGroup.get(
        'cardiovascularDisease'
      ).value;
      this.otherModel.chronicDisease.diagnosed =
        this.otherGroup.get('chronicDisease').value;
      this.otherModel.chronicDisease.optionalText =
        this.otherGroup.get('chronicDiseaseOptionalText').value ?? '';
      this.otherModel.recentSurgery = this.otherGroup.get('surgery').value;
      this.otherModel.fluidsRequirement =
        this.otherGroup.get('fluidsRequirement').value;
      this.otherModel.personalGoal =
        this.otherGroup.get('personalGoal').value ?? '';
      return true;
    }
    return false;
  }

  cancelPatientCreation() {
    this.router.navigate(['dashboard', this.physiotherapist.role]);
  }

  openModal(content, opt: any = {}) {
    this.modalService.open(content, { size: 'lg', ...opt });
  }

  validateCopdDuration() {
    if (!this || !this.copdGroup) return;
    const yearsValid =
      !this.copdGroup.get('durationYears').value ||
      this.copdGroup.get('durationYears').value > 0;
    let monthsValid =
      !this.copdGroup.get('durationMonths').value ||
      (this.copdGroup.get('durationMonths').value > 0 &&
        this.copdGroup.get('durationMonths').value < 12);

    if (
      this.copdGroup.get('durationYears').value &&
      yearsValid &&
      monthsValid
    ) {
      this.copdGroup.get('durationMonths').clearValidators();
      this.copdGroup.get('durationMonths').updateValueAndValidity();
      return;
    }
    if (
      this.copdGroup.get('durationMonths').value &&
      yearsValid &&
      monthsValid
    ) {
      this.copdGroup.get('durationYears').clearValidators();
      this.copdGroup.get('durationYears').updateValueAndValidity();
      return;
    }

    this.copdGroup
      .get('durationYears')
      .setValidators([Validators.required, Validators.min(1)]);
    this.copdGroup
      .get('durationMonths')
      .setValidators([
        Validators.required,
        Validators.min(1),
        Validators.max(11),
      ]);

    this.copdDurationError =
      this.copdGroup.get('durationMonths').value === 0 || !monthsValid
        ? this.copdGroup.get('durationMonths')
        : this.copdGroup.get('durationYears');

    return;
  }

  furtherDetailOnYes(
    formGroup: string,
    parentPath: string,
    childPath: string
  ): ValidationErrors | null {
    if (this[formGroup] && this[formGroup].get(parentPath)) {
      if (this[formGroup].get(parentPath).value == true) {
        this[formGroup].get(childPath).setValidators(Validators.required);
      } else {
        this[formGroup].get(childPath).clearValidators();
        this[formGroup].get(childPath).updateValueAndValidity();
      }
    }
    return null;
  }

  initializePersonalInfoForm() {
    this.personalInfoModel.careDiagnosis = new CareDiagnosis();
    this.personalInformationGroup = new UntypedFormGroup({
      firstName: new UntypedFormControl(this.personalInfoModel.firstName, [
        Validators.required,
        Validators.pattern(this.alphabetPattern),
      ]),
      lastName: new UntypedFormControl(this.personalInfoModel.lastName, [
        Validators.required,
        Validators.pattern(this.alphabetPattern),
      ]),
      contactReason: new UntypedFormControl(
        this.personalInfoModel.contactReason,
        [Validators.required]
      ),
      reasonExplanation: new UntypedFormControl(
        this.personalInfoModel.reasonExplanation,
        [Validators.required]
      ),
      age: new UntypedFormControl(this.personalInfoModel.age, [
        Validators.required,
        birthdateValidation(),
      ]),
      email: new UntypedFormControl(this.personalInfoModel.email, [
        Validators.required,
        Validators.pattern(this.emailPattern),
      ]),
      phone: new UntypedFormControl(this.personalInfoModel.phone, [
        Validators.required,
        Validators.pattern(this.phonePattern),
      ]),
      height: new UntypedFormControl(this.personalInfoModel.height, [
        Validators.required,
        Validators.min(0),
      ]),
      weight: new UntypedFormControl(this.personalInfoModel.weight, [
        Validators.required,
        Validators.min(0),
      ]),
      gender: new UntypedFormControl(this.personalInfoModel.gender, [
        Validators.required,
      ]),
      smoking: new UntypedFormControl(this.personalInfoModel.smoking, [
        Validators.required,
      ]),
      status: new UntypedFormControl(this.personalInfoModel.status, [
        Validators.required,
      ]),
      careDiagnosis: new UntypedFormControl(
        this.personalInfoModel.careDiagnosis.diagnosed,
        [
          Validators.required,
          () => {
            return this.furtherDetailOnYes(
              'personalInformationGroup',
              'careDiagnosis',
              'careDiagnosisDeg'
            );
          },
        ]
      ),
      careDiagnosisDeg: new UntypedFormControl(
        this.personalInfoModel.careDiagnosis.degree
      ),
    });
  }

  initializeCopdForm() {
    this.copdModel.medicalAids = {};
    this.copdModel.extraSymptoms = {};
    this.copdModel.actions = {};
    this.copdModel.symptoms = {};
    this.copdModel.symptoms = {};
    this.copMedicalAids = [
      { name: 'inhalers', control: new UntypedFormControl() },
      { name: 'trainer', control: new UntypedFormControl() },
      { name: 'flow', control: new UntypedFormControl() },
      { name: 'lung', control: new UntypedFormControl() },
      { name: 'pulse', control: new UntypedFormControl() },
    ];
    this.copMedicalAids.forEach(a => {
      this.copdModel.medicalAids[a.name] = false
    })
    this.copdActions = [
      { name: 'pulSport', control: new UntypedFormControl() },
      { name: 'rehab', control: new UntypedFormControl() },
      { name: 'therapy', control: new UntypedFormControl() },
      { name: 'group', control: new UntypedFormControl() },
      { name: 'sport', control: new UntypedFormControl() },
    ];
    this.copdActions.forEach((s) => {
      this.copdModel.actions[s.name] = false;
    });
    this.copdSymptoms = [
      { name: 'cough', control: new UntypedFormControl() },
      { name: 'secretion', control: new UntypedFormControl() },
      { name: 'shortBreath', control: new UntypedFormControl() },
      { name: 'weightGain', control: new UntypedFormControl() },
      { name: 'weightLoss', control: new UntypedFormControl() },
      { name: 'tightChest', control: new UntypedFormControl() },
      { name: 'fatigue', control: new UntypedFormControl() },
      { name: 'immobility', control: new UntypedFormControl() },
      { name: 'restrictions', control: new UntypedFormControl() },
      { name: 'exacerbations', control: new UntypedFormControl() },
      { name: 'sleepDisturb', control: new UntypedFormControl() },
      { name: 'appetiteLoss', control: new UntypedFormControl() },
      { name: 'moodSwings', control: new UntypedFormControl() },
      { name: 'backPain', control: new UntypedFormControl() },
      { name: 'concentration', control: new UntypedFormControl() },
      { name: 'stress', control: new UntypedFormControl() },
    ];
    this.copdSymptoms.forEach((s) => {
      this.copdModel.symptoms[s.name] = false;
    });


    this.copdGroup = new UntypedFormGroup({
      classification: new UntypedFormControl(this.copdModel.classification, [
        Validators.required,
      ]),
      durationYears: new UntypedFormControl(this.copdYears, [
        Validators.required,
        Validators.min(1),
      ]),
      durationMonths: new UntypedFormControl(this.copdMonths, [
        Validators.required,
        Validators.min(1),
        Validators.max(11),
      ]),
      ongoingTreatment: new UntypedFormControl(
        this.copdModel.ongoingTreatment,
        [Validators.required]
      ),
      oxygen: new UntypedFormControl(this.copdModel.o2Requirement, [
        Validators.required,
      ]),
      newSymptom: new UntypedFormControl(),
    });

    this.copdDurationError = this.copdGroup.get('durationYears');
  }

  initializeOtherForm() {
    this.otherModel.chronicDisease = new ChronicDisease();
    this.otherModel.difficultActs = {};
    this.otherModel.aids = {};
    this.otherActivities = [
      { name: 'walking', control: new UntypedFormControl() },
      { name: 'household', control: new UntypedFormControl() },
      { name: 'hygiene', control: new UntypedFormControl() },
      { name: 'stairs', control: new UntypedFormControl() },
      { name: 'dressing', control: new UntypedFormControl() },
      { name: 'communication', control: new UntypedFormControl() },
      { name: 'leisure', control: new UntypedFormControl() },
      { name: 'shopping', control: new UntypedFormControl() },
      { name: 'society', control: new UntypedFormControl() },
    ];
    this.otherActivities.forEach((a) => {
      this.otherModel.difficultActs[a.name] = false;
    });
    this.otherMedicalAids = [
      { name: 'stick', control: new UntypedFormControl() },
      { name: 'rollator', control: new UntypedFormControl() },
      { name: 'wheelchair', control: new UntypedFormControl() },
      { name: 'lift', control: new UntypedFormControl() },
      { name: 'visual', control: new UntypedFormControl() },
      { name: 'hearing', control: new UntypedFormControl() },
      { name: 'grasping', control: new UntypedFormControl() },
    ];
    this.otherMedicalAids.forEach((a) => {
      this.otherModel.aids[a.name] = false;
    });
    this.otherGroup = new UntypedFormGroup({
      sensitiveVeins: new UntypedFormControl(this.otherModel.sensitiveVeins, [
        Validators.required,
      ]),
      cardiovascularDisease: new UntypedFormControl(
        this.otherModel.cardiovascularDisease,
        [Validators.required]
      ),
      chronicDisease: new UntypedFormControl(
        this.otherModel.chronicDisease.diagnosed,
        [
          Validators.required,
          () => {
            return this.furtherDetailOnYes(
              'otherGroup',
              'chronicDisease',
              'chronicDiseaseOptionalText'
            );
          },
        ]
      ),
      chronicDiseaseOptionalText: new UntypedFormControl(
        this.otherModel.chronicDisease.optionalText
      ),
      surgery: new UntypedFormControl(this.otherModel.recentSurgery, [
        Validators.required,
      ]),
      difficultActs: new UntypedFormControl(),
      otherAids: new UntypedFormControl(),
      fluidsRequirement: new UntypedFormControl(
        this.otherModel.fluidsRequirement,
        [Validators.required]
      ),
      personalGoal: new UntypedFormControl(),
    });
  }

  goToPersonalInfo() {
    if (this.isPersonalCardCompleted()) {
      this.personalInfoCard = true;
      this.copdCard = false;
      this.otherCard = false;
    }
  }

  goToCopd() {
    if (this.isCopdCardCompleted()) {
      this.personalInfoCard = false;
      this.copdCard = true;
      this.otherCard = false;
    }
  }

  goToOther() {
    if (this.isOtherCardCompleted()) {
      this.personalInfoCard = false;
      this.copdCard = false;
      this.otherCard = true;
    }
  }



  customizeOnboardingForm() {
    this.onboardingCardList.push({ personalInformationCard: true });
    this.onboardingCardList.push({ copdCard: false });
    this.onboardingCardList.push({ otherCard: false });


  }

  checkCardExistence(cardTitle: string, role: string) {
    let keyExists = false;
    let showCard: boolean;
    this.onboardingCardList.forEach((card) => {
      if (cardTitle in card) {
        keyExists = true;
        showCard = card[cardTitle];
      }
    });
    if (role === 'main-card') {
      if (keyExists) {
        return showCard;
      }
      return false;
    }
    return keyExists;
  }



  isActive(cardTitle: string) {
    let result;
    this.onboardingCardList.forEach((card, index) => {
      if (cardTitle in card) {
        if (index === this.activeIndex) {
          result = true;
        }
      }
    });
    if (result) {
      return true;
    }
    return false;
  }

  outlineNavigationClick(index: number) {
    if (index <= this.firstUnFilledCardIndex) {
      this.activeCardIndex = index
    }
  }

  isCardCompleted(cardTitle: string) {
    if (cardTitle === 'personalInformationCard') {
      return this.isPersonalCardCompleted();
    } else if (cardTitle === 'copdCard') {
      return this.isCopdCardCompleted();
    } else if (cardTitle === 'otherCard') {
      return this.isOtherCardCompleted();
    }

    // else if (cardTitle === 'diseaseHistoryCard') {
    //   return this.isDiseaseHistoryCardCompleted();
    // } else if (cardTitle === 'lifeStyleCard') {
    //   return this.isLifestyleCardCompleted();
    // } else if (cardTitle === 'medicationsCard') {
    //   return this.isMedicalCardCompleted();
    // } else if (cardTitle === 'breathingCard') {
    //   return this.isBreathingCardCompleted();
    // } else if (cardTitle === 'personalStoryCard') {
    //   return this.isPersonalStoryCardCompleted();
    // }
  }
  convertBirthdateFormat(birthdate) {
    const date = birthdate.split('-');
    var birthday = date[2] + '.' + date[1] + '.' + date[0];
    return birthday;
  }

  handleOtherActivityChange(event) {
    this.otherModel.difficultActs[event.target.value] = event.target.checked;
  }

  handleOtherAidChange(event) {
    this.otherModel.aids[event.target.value] = event.target.checked;
  }

  handleCopdAidChange(event) {
    this.copdModel.medicalAids[event.target.value] = event.target.checked;
  }

  handleCopdActionChange(event) {
    this.copdModel.actions[event.target.value] = event.target.checked;
  }

  addCopdSymptom() {
    const newSymptom = this.copdGroup.get('newSymptom').value;
    if (newSymptom) {
      this.copdSymptoms.push({
        name: newSymptom,
        control: new UntypedFormControl(),
      });
      this.copdModel.extraSymptoms[newSymptom] = true;
      this.copdGroup.get('newSymptom').reset();
      setTimeout(() => {
        (
          document.getElementById(
            'input-symptom' + (this.copdSymptoms.length - 1)
          ) as HTMLInputElement
        ).checked = true;
      }, 100);
    }
  }

  handleCopdSymptomChange(event) {
    this.copdModel.symptoms[event.target.value] = event.target.checked;
  }
}
