import { IAudioPlayer } from "exercise_engine";

export class SoundPlayer {
    private _isPlaying: boolean;

    constructor(private id: string) { }
    pause() {
        const audioElement: HTMLAudioElement = document.getElementById(this.id) as HTMLAudioElement;
        if (!audioElement) return;
        audioElement.pause();
        this._isPlaying = false;
    }
    playFromUrl(src: string, onStarted, onEnded, prio: boolean = false) {
        if (!prio && this._isPlaying) {
            return;
        }
        if (prio && this._isPlaying) {
            this.pause()
        }
        const audioElement: HTMLAudioElement = document.getElementById(this.id) as HTMLAudioElement;
        if (!audioElement) return;

        audioElement.src = src;
        audioElement.load();
        onStarted();
        audioElement.play();
        this._isPlaying = true;
        audioElement.addEventListener('ended', (e) => {
            onEnded()
            this._isPlaying = false;
        })
        this.logAudioInfo('playFromUrl:', audioElement)
    }
    playFromBase64(b64String: string, onStarted, onEnded, prio: boolean = false) {
        if (!prio && this._isPlaying) {
            return;
        }
        if (prio && this._isPlaying) {
            this.pause()
        }
        const audioElement: HTMLAudioElement = document.getElementById(this.id) as HTMLAudioElement;
        if (!audioElement) return;
        const binary = this.convertDataURIToBinary(b64String);
        if (!binary) return;
        const blob = new Blob([binary], { type: 'audio/ogg' });
        const blobUrl = URL.createObjectURL(blob);
        audioElement.src = blobUrl;
        audioElement.load();
        onStarted();
        this._isPlaying = true;
        audioElement.play();
        audioElement.addEventListener('ended', (e) => {
            this._isPlaying = false;
            onEnded()
        })
        this.logAudioInfo('playFromBase64', audioElement)
    }
    private convertDataURIToBinary(dataURI) {
        try {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));

            for (let i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        } catch (error) {

        }
        return null;
    }
    private logAudioInfo(fnName: string, el: HTMLAudioElement) {

    }
}
export class WarningTracker {
    private key: string = '';
    private last_play_time: number;
    private continuous_play_count: number;
    private next_valid_play_time: number;
    shouldPlay(key): boolean {
        if (key != this.key) this.setLastKey(key)
        const ret = Date.now() >= this.next_valid_play_time;
        if (ret)
            this.setLastKey(key)
        return ret;
    }
    private setLastKey(key) {
        if (key != this.key) {
            this.key = key
            this.continuous_play_count = 0
            this.last_play_time = Date.now()
            this.next_valid_play_time = this.last_play_time
        } else {
            this.continuous_play_count += 1;
            this.next_valid_play_time = (Date.now() + 3000) + (this.continuous_play_count * 1300)
        }


    }
}


export class AudioPlayer implements IAudioPlayer {
    private _id!: string;
    private _isPlaying: boolean;
    private _duration: number;
    constructor(id: string) {
        this._id = id
    }
    isPlaying(): boolean {
       return this._isPlaying
    }
    playAudio(data: string,priority: number,isBeep:boolean): void {
        if (!data) return;

        const beepsAudioElement: HTMLAudioElement | null = document.getElementById(this._id+'_beeps') as HTMLAudioElement | null;
        if(isBeep){
            beepsAudioElement.src = data
            beepsAudioElement.play()
            return
        }
        const audioElement: HTMLAudioElement | null = document.getElementById(this._id) as HTMLAudioElement | null;
        if (!audioElement) return;

        // Set the audio source to the data URI
        audioElement.src = data;

        // Listen for metadata to be loaded
        audioElement.addEventListener('loadedmetadata', () => {
            this._duration = audioElement.duration;
        }, { once: true }); // Remove listener after it's called once

        // Listen for the audio to end
        audioElement.addEventListener('ended', () => {
            this._isPlaying = false;
            // No need to revoke if using a data URI
        }, { once: true }); // Remove listener after it's called once

        // Listen for playing state
        audioElement.addEventListener('playing', () => {
            const currentTime = audioElement.currentTime;  // Current playback time (in seconds)
            const duration = audioElement.duration;          // Total duration of the audio (in seconds)
            const volume = audioElement.volume;              // Current volume level (between 0.0 and 1.0)
            const isPaused = audioElement.paused;            // Boolean indicating whether the audio is paused
            this._isPlaying = !isPaused;
        });

        // Load and play the audio
        //audioElement.load();
        audioElement.play().catch(error => {
            console.error('Error playing audio:', error);
        });
        this._isPlaying = true;
    }

    stopAudio(): void {
        const audioElement: HTMLAudioElement = document.getElementById(this._id) as HTMLAudioElement;
        if (!audioElement) return;
        audioElement.pause()
    }
    getAudioLength(url: string): Promise<number> {
        return new Promise((resolve, reject) => {
            const tmpID = `id88`;
            let audioElement: HTMLAudioElement | null = document.getElementById(tmpID) as HTMLAudioElement | null;

            if (!audioElement) {
                audioElement = document.createElement("audio");
                audioElement.id = tmpID; // Set the ID for future reference
                document.body.appendChild(audioElement);
            }
            
            audioElement.src = url;

            // Listen for the loadedmetadata event
            audioElement.addEventListener('loadedmetadata', () => {
                const duration = audioElement?.duration || 0; // Get duration safely
                audioElement.remove(); // Remove the audio element after getting the duration
                resolve(duration); // Resolve with the audio duration
            });

            // Handle error if the audio fails to load
            audioElement.addEventListener('error', (e) => {
                console.error('Error loading audio:', e);
                audioElement.remove(); // Clean up
                reject(new Error('Failed to load audio'));
            });
        });
    }


}