<div *ngIf="loading" class="full-screen-center" ngClass="loading">
  <div class="text-primary" role="status">
    <img [src]="'assets/Logo/spinner-border.gif'" alt="Spinner" id="spinner" />
  </div>
</div>

<div class="d-flex align-items-center container-fluid mb-3">
  <button
    style="all: unset; border-radius: 8px; padding: 6px 12px; cursor: pointer"
    class="btn-outline-secondary d-flex align-items-center"
    (click)="showMetrics = !showMetrics"
  >
    <span style="font-size: 2rem">&#9776;</span>
  </button>

  <h2
    style="
      margin: 0;
      font-size: 20px;
      font-weight: 700;
      color: #314f6d;
      text-align: center;
      margin-top: 8px;
    "
  >
    {{ "patientDetails.performanceOverview.metrics" | translate }}
  </h2>
</div>

<div
  *ngIf="!loading"
  class="container-fluid d-flex g-4 column-container-850 flex-wrap justify-content-center"
  [ngStyle]="{ 'align-items': isSmallScreen ? 'center' : 'flex-start' }"
>
  <ul
    class="list g-2 flex-column"
    [ngStyle]="{
      width: isSmallScreen ? '100%' : showMetrics ? '27%' : '1%',
      opacity: showMetrics ? '1' : '0',
      'pointer-events': showMetrics ? 'auto' : 'none',
      display: isSmallScreen && !showMetrics ? 'none' : 'flex'
    }"
  >
    <li>
      <button
        (click)="setActiveView('activity')"
        style="
          width: 100%;
          padding: 12px 24px;
          border-radius: 16px;
          background-color: #fff;
        "
        [ngClass]="{
          'active-border': activeView === 'activity',
          'inactive-border': activeView !== 'activity'
        }"
      >
        {{ "patientDetails.performanceOverview.activity" | translate }}
      </button>
    </li>

    <li>
      <button
        (click)="setActiveView('survey-results')"
        style="
          width: 100%;
          padding: 12px 24px;
          border-radius: 16px;
          background-color: #fff;
        "
        [ngClass]="{
          'active-border': activeView === 'survey-results',
          'inactive-border': activeView !== 'survey-results'
        }"
      >
        {{ "patientDetails.performanceOverview.surveyResults" | translate }}
      </button>
    </li>

    <li *ngFor="let measurement of measurements">
      <button
        [disabled]="!measurement.active"
        (click)="setActiveView(measurement._id); setActiveData(measurement)"
        style="
          width: 100%;
          padding: 12px 24px;
          border-radius: 16px;
          background-color: #fff;
        "
        [ngClass]="
          activeView === measurement._id ? 'active-border' : 'inactive-border'
        "
      >
        {{ measurement.display_name }}
      </button>
    </li>
  </ul>

  <div
    *ngIf="activeView === 'activity'"
    class="d-flex justify-content-center"
    [ngStyle]="{
      width: isSmallScreen ? '99%' : showMetrics ? '70%' : '95%',
      'min-width': '250px',
      'max-width': '1500px'
    }"
  >
    <app-activity-card
      [patientId]="patientId"
      [stepGoal]="patientDetails.step_goal"
      style="width: 100%"
    ></app-activity-card>
  </div>

  <div
    *ngIf="activeView === 'survey-results'"
    class="d-flex justify-content-center"
    [ngStyle]="{
      width: isSmallScreen ? '99%' : showMetrics ? '70%' : '95%',
      'min-width': '250px',
      'max-width': '1500px'
    }"
  >
    <app-survey-card
      [patientId]="patientId"
      [tab]="'daily'"
      (viewReady)="(0)"
      style="width: 100%"
    ></app-survey-card>
  </div>

  <div
    *ngIf="activeView === '672cb2214be9820dd1ccef05'"
    class="d-flex justify-content-center"
    [ngStyle]="{
      width: isSmallScreen ? '99%' : showMetrics ? '70%' : '95%',
      'min-width': '250px',
      'max-width': '1500px'
    }"
  >
    <app-measurement-container
      [patientId]="patientId"
      [measurementName]="activeData.display_name"
      [measurementId]="activeData._id"
      [measurementUnit]="activeData.unit"
      style="width: 100%"
    ></app-measurement-container>
  </div>

  <div
    *ngIf="activeView === '9sw7cb2214blp00ucsif05'"
    class="d-flex justify-content-center"
    [ngStyle]="{
      width: isSmallScreen ? '99%' : showMetrics ? '70%' : '95%',
      'min-width': '250px',
      'max-width': '1500px'
    }"
  >
    <app-measurement-container
      [patientId]="patientId"
      [measurementName]="activeData.display_name"
      [measurementUnit]="activeData.unit"
      [measurementId]="activeData._id"
      style="width: 100%"
    ></app-measurement-container>
  </div>

  <div
    *ngIf="activeView === '9sw7cb2214blp00ucsiw06'"
    class="d-flex justify-content-center"
    [ngStyle]="{
      width: isSmallScreen ? '99%' : showMetrics ? '70%' : '95%',
      'min-width': '250px',
      'max-width': '1500px'
    }"
  >
    <app-measurement-container
      [patientId]="patientId"
      [measurementName]="activeData.display_name"
      [measurementUnit]="activeData.unit"
      [measurementId]="activeData._id"
      style="width: 100%"
    ></app-measurement-container>
  </div>

  <div
    *ngIf="activeView === '9sw7cb2214blp00ucsiw07'"
    class="d-flex justify-content-center"
    [ngStyle]="{
      width: isSmallScreen ? '99%' : showMetrics ? '70%' : '95%',
      'min-width': '250px',
      'max-width': '1500px'
    }"
  >
    <app-measurement-container
      [patientId]="patientId"
      [measurementName]="activeData.display_name"
      [measurementUnit]="activeData.unit"
      [measurementId]="activeData._id"
      style="width: 100%"
    ></app-measurement-container>
  </div>

  <div
    *ngIf="activeView === '9sw7cb2214blp00ucsiw08'"
    class="d-flex justify-content-center"
    [ngStyle]="{
      width: isSmallScreen ? '99%' : showMetrics ? '70%' : '95%',
      'min-width': '250px',
      'max-width': '1500px'
    }"
  >
    <app-measurement-container
      [patientId]="patientId"
      [measurementName]="activeData.display_name"
      [measurementUnit]="activeData.unit"
      [measurementId]="activeData._id"
      style="width: 100%"
    ></app-measurement-container>
  </div>

  <div
    *ngIf="activeView === '9sw7cb2214blp00ucsiw09'"
    class="d-flex justify-content-center"
    [ngStyle]="{
      width: isSmallScreen ? '99%' : showMetrics ? '70%' : '95%',
      'min-width': '250px',
      'max-width': '1500px'
    }"
  >
    <app-measurement-container
      [patientId]="patientId"
      [measurementName]="activeData.display_name"
      [measurementUnit]="activeData.unit"
      [measurementId]="activeData._id"
      style="width: 100%"
    ></app-measurement-container>
  </div>
</div>
