<div *ngIf="isSingle">
    <div *ngIf="totalValue!==undefined" class="counter center">
        <div class="counter-text">
            <span>{{"exercise." + valueName | translate }}</span>
        </div>
        <div class="counter-number">
            <span class="text-white">{{ countType==='down'? (totalValue - value): value}}</span>
        </div>
    </div>
</div>
<div *ngIf="!isSingle">

    <div class="counter left" *ngIf="leftValue!=undefined">
        <div class="counter-text">
            <span>{{ "exercise." + leftValueName | translate }}</span>
        </div>
        <div class="counter-number">

            <span class="text-white">{{totalValue - leftValue}}</span>
        </div>
    </div>
    <div class="counter right" *ngIf="rightValue!=undefined">
        <div class="counter-text">
            <span>{{ "exercise." + rightValueName | translate }}</span>
        </div>
        <div class="counter-number">
            <span class="text-white">{{totalValue - rightValue}}</span>
        </div>
    </div>
</div>