<section class="slice slice-lg pt-0 pb-0">
  <div class="container-fluid pb-1">
    <section class="slice slice-lg pt-0 pb-0 px-custom-sm w-100">
      <div class="row justify-content-between m-0">
        <div class="container-fluid pb-0 d-flex flex-column g-1">
          <div class="d-flex justify-content-between">
            <div
              class="justify-content-between align-items-center w-100 d-none d-sm-flex"
            >
              <div class="back-button" style="flex: 1">
                <a class="d-flex w-10 g-2" (click)="goBack()">
                  <img [src]="'assets/img/svg/icons/left-arrow.svg'" />
                  <span class="font-weight-700">{{
                    "patientDetails.back" | translate
                  }}</span>
                </a>
              </div>

              <h2 class="responsive-title" style="flex: 1; margin: 0">
                {{ patientDetails["personal_information"].first_name }}
                {{ patientDetails["personal_information"].last_name }}
              </h2>

              <div
                class="d-flex g-4"
                style="position: relative; justify-content: end; flex: 1"
              >
                <div ngbDropdown aria-labelledby="dropdownMenuButton">
                  <button
                    class="d-flex align-items-center justify-content-around pl-3 pr-3 g-2"
                    style="
                      max-height: 35px;
                      border-radius: 4px;
                      background-color: #314f6d;
                      border: none;
                    "
                    ngbDropdownToggle
                  >
                    <img
                      [src]="'assets/img/svg/icons/plus.svg'"
                      alt="World"
                      style="height: 1.3rem; pointer-events: none"
                    />
                    <div style="color: #fff; pointer-events: none">
                      {{ "generalTerms.create" | translate }}
                    </div>

                    <i
                      class="fas fa-angle-down"
                      style="
                        font-size: 1.3rem;
                        color: #fff;
                        margin-left: 8px;
                        margin-right: 0;
                      "
                    ></i>
                    <i
                      class="fas fa-angle-up"
                      style="font-size: 1.3rem; color: #fff; margin-left: 8px"
                    ></i>
                  </button>
                  <div
                    ngbDropdownMenu
                    aria-labelledby="dropdownBasic1"
                    style="min-width: 100%"
                  >
                    <button
                      ngbDropdownItem
                      style="text-align: left"
                      (click)="navigateTo('create-program')"
                    >
                      <img
                        [src]="'assets/img/svg/icons/calendar-icon.svg'"
                        alt="Image placeholder"
                        class="mr-3"
                      />
                      <span class="list-item">{{
                        "patientDetails.newExerciseProgram" | translate
                      }}</span>
                    </button>
                    <button
                      ngbDropdownItem
                      style="text-align: left"
                      (click)="navigateTo('calendar')"
                    >
                      <img
                        [src]="'assets/img/svg/icons/clock-icon.svg'"
                        alt="Image placeholder"
                        class="mr-3"
                      />
                      <span class="list-item">{{
                        "patientDetails.newAppointment" | translate
                      }}</span>
                    </button>
                  </div>
                </div>

                <button
                  type="button"
                  class="btn btn-warning btn-circle btn-sm"
                  (click)="openModal(InformationModal)"
                >
                  <i
                    class="text-end"
                    class="fas fa-question"
                    style="color: white"
                  ></i>
                </button>
              </div>
            </div>

            <div
              class="justify-content-between align-items-center w-100 d-block d-sm-none pb-3"
            >
              <h2 class="responsive-title">
                {{ patientDetails["personal_information"].first_name }}
                {{ patientDetails["personal_information"].last_name }}
              </h2>

              <div class="d-flex w-100" style="justify-content: space-between">
                <div class="back-button">
                  <a class="d-flex w-10 g-2" (click)="goBack()">
                    <img [src]="'assets/img/svg/icons/left-arrow.svg'" />
                    <span class="font-weight-700">{{
                      "patientDetails.back" | translate
                    }}</span>
                  </a>
                </div>

                <div class="d-flex g-4">
                  <div ngbDropdown aria-labelledby="dropdownMenuButton">
                    <button
                      class="d-flex align-items-center justify-content-around pl-3 pr-3 g-2"
                      style="
                        max-height: 35px;
                        border-radius: 4px;
                        background-color: #314f6d;
                        border: none;
                      "
                      ngbDropdownToggle
                    >
                      <img
                        [src]="'assets/img/svg/icons/plus.svg'"
                        alt="World"
                        style="height: 1.3rem; pointer-events: none"
                      />
                      <div style="color: #fff; pointer-events: none">
                        {{ "generalTerms.create" | translate }}
                      </div>

                      <i
                        class="fas fa-angle-down"
                        style="
                          font-size: 1.3rem;
                          color: #fff;
                          margin-left: 8px;
                          margin-right: 0;
                        "
                      ></i>
                      <i
                        class="fas fa-angle-up"
                        style="font-size: 1.3rem; color: #fff; margin-left: 8px"
                      ></i>
                    </button>
                    <div
                      ngbDropdownMenu
                      aria-labelledby="dropdownBasic1"
                      style="min-width: 100%"
                    >
                      <button
                        ngbDropdownItem
                        style="text-align: left"
                        (click)="navigateTo('create-program')"
                      >
                        <img
                          [src]="'assets/img/svg/icons/calendar-icon.svg'"
                          alt="Image placeholder"
                          class="mr-3"
                        />
                        <span class="list-item">{{
                          "patientDetails.newExerciseProgram" | translate
                        }}</span>
                      </button>
                      <button
                        ngbDropdownItem
                        style="text-align: left"
                        (click)="navigateTo('calendar')"
                      >
                        <img
                          [src]="'assets/img/svg/icons/clock-icon.svg'"
                          alt="Image placeholder"
                          class="mr-3"
                        />
                        <span class="list-item">{{
                          "patientDetails.newAppointment" | translate
                        }}</span>
                      </button>
                    </div>
                  </div>

                  <button
                    type="button"
                    class="btn btn-warning btn-circle btn-sm "
                    (click)="openModal(InformationModal)"
                  >
                    <i
                      class="text-end"
                      class="fas fa-question"
                      style="color: white"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ul class="tabs">
            <li
              *ngFor="let t of tabs; let i = index"
              [class.active]="selectedTab.translationKey === t.translationKey"
              (click)="selectTab(i)"
              class="tab truncate"
            >
              {{ t.title }}
            </li>
          </ul>
        </div>
        <div class="container-fluid d-flex flex-column">
          <router-outlet></router-outlet>
          <!-- This will render the selected tab's component -->
        </div>
      </div>
    </section>
  </div>
</section>

<ng-template #InformationModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      <img
        class="mr-2"
        alt="Image placeholder"
        [src]="'assets/Logo/Breathment Logo_rev_white.jpg'"
        style="height: 50px"
        id="navbar-mobilr-logo"
      />
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="section">
      <h6>{{ "tutorial.sections.profile.title" | translate }}</h6>
      <ul>
        <li>{{ "tutorial.sections.profile.points.settings" | translate }}</li>
        <li>{{ "tutorial.sections.profile.points.edit" | translate }}</li>
      </ul>
    </div>

    <div class="section">
      <h6>{{ "tutorial.sections.calendar.title" | translate }}</h6>
      <ul>
        <li>{{ "tutorial.sections.calendar.points.view" | translate }}</li>
      </ul>
    </div>

    <div class="section">
      <h6>{{ "tutorial.sections.exercisePrograms.title" | translate }}</h6>
      <ul>
        <li>
          {{ "tutorial.sections.exercisePrograms.points.view" | translate }}
        </li>
      </ul>
    </div>

    <div class="section">
      <h6>{{ "tutorial.sections.performanceOverview.title" | translate }}</h6>
      <ul>
        <li>
          {{ "tutorial.sections.performanceOverview.points.view" | translate }}
        </li>
      </ul>
    </div>

    <div class="section">
      <h6>{{ "tutorial.sections.healthData.title" | translate }}</h6>
      <ul>
        <li>{{ "tutorial.sections.healthData.points.view" | translate }}</li>
      </ul>
    </div>

    <div class="section">
      <h6>{{ "tutorial.sections.notifications.title" | translate }}</h6>
      <ul>
        <li>{{ "tutorial.sections.notifications.points.view" | translate }}</li>
      </ul>
    </div>

    <div class="section">
      <h6>{{ "tutorial.sections.create.title" | translate }}</h6>
      <ul>
        <li>
          {{ "tutorial.sections.create.points.newExerciseProgram" | translate }}
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-sm btn-secondary"
      (click)="modal.close()"
    >
      {{ "generalTerms.close" | translate }}
    </button>
  </div>
</ng-template>
