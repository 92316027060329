
import { Component, Input, SimpleChanges } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { el } from 'date-fns/locale';
import { Color } from 'ng2-charts';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-measurement-chart',
  templateUrl: './measurement-chart.component.html',
  styleUrls: ['./measurement-chart.component.css'],
})
export class MeasurementChartComponent {
  @Input() plotData: any;
  @Input() type: string;
  @Input() period: string;

  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: string[];
  public lineChartOptions: any = {
    responsive: true,
    aspectRatio: 2,
    legend: {
      display: false,
    },
    elements: {
      line: {
        tension: 0.6,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: '#BABABACC',
            borderDash: [3, 3],
          },
          ticks: {
            fontColor: '#989898',
          },
          scaleLabel: {
            labelString: '',
            display: true,
            fontColor: 'white',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: '#989898',
          },
          scaleLabel: {
            labelString: '',
            display: true,
            fontColor: 'white',
          },
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    {
      borderColor: '#fff',
      backgroundColor: '#fff',
    },
  ];
  public lineChartType = 'line';
  customTooltipsMap: { [label: string]: number[] } = {};

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    if (this.type === 'steps') {
      this.processChartDataSteps();
    } else if (this.type === 'measurements') {
      this.processChartData();
      this.lineChartColors = [
        {
          borderColor: '#4EA925',
          backgroundColor: '#4EA925',
        },
      ];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.lineChartData = [];

    if (changes['plotData'] && this.plotData) {
      this.lineChartData = [];
      this.processChartData();
    }
  }

  processChartDataSteps() {
    if (!this.plotData || this.plotData.length === 0) return;

    let chartData: number[] = [];
    let chartLabels: string[] = [];

    this.plotData.forEach((element) => {
      if (element.value !== null) {
        chartLabels.push(element.date);
        chartData.push(element.steps);
      }
    });

    this.lineChartData = [{ data: chartData, fill: false }];
    this.lineChartLabels = chartLabels;
  }

  processChartData() {
    console.log(this.period, this.plotData);

    let chartData: number[] = [];
    let chartLabels: string[] = [];
    let currentLang = this.translate.currentLang || 'en';

    if (this.period === 'D') {
      if (!this.plotData || this.plotData.length === 0) return;

      this.plotData.forEach((element) => {
        if (element.value !== null) {
          const date = new Date(element.date);
          const timeString = date.toLocaleTimeString(currentLang, {
            hour: '2-digit',
            minute: '2-digit',
          });

          chartLabels.push(timeString);
          chartData.push(element.value);
        }
      });

      this.lineChartData = [{ data: chartData, fill: false }];
      this.lineChartLabels = chartLabels;
    } else if (this.period === 'W') {
      if (!this.plotData || this.plotData.length === 0) return;

      const groupedData: { [dateKey: string]: number[] } = {};
      this.customTooltipsMap = {};

      this.plotData.forEach((entry) => {
        if (entry.value !== null) {
          const date = new Date(entry.date);
          const dateKey = date.toISOString().split('T')[0];

          if (!groupedData[dateKey]) {
            groupedData[dateKey] = [];
          }
          groupedData[dateKey].push(entry.value);
        }
      });

      const weekDays = this.getWeekDays(currentLang);
      const dayMap: { [key: string]: number | null } = {};
      const highlightMap: { [key: string]: boolean } = {};

      weekDays.forEach((day) => {
        dayMap[day] = null;
        highlightMap[day] = false;
      });

      Object.keys(groupedData).forEach((dateKey) => {
        const date = new Date(dateKey);
        const weekday = date.toLocaleDateString(currentLang, {
          weekday: 'short',
        });

        const values = groupedData[dateKey];
        const avg = this.average(values);

        if (weekday in dayMap) {
          this.customTooltipsMap[weekday] = values;
          dayMap[weekday] = avg;

          if (values.length > 1) {
            highlightMap[weekday] = true;
          }
        }
      });

      const pointColors: string[] = [];

      weekDays.forEach((day) => {
        chartLabels.push(day);
        chartData.push(dayMap[day] ?? null);

        const color = highlightMap[day] ? '#275413' : '#4EA925';
        pointColors.push(color);
      });

      this.lineChartData = [
        {
          data: chartData,
          fill: false,
          pointBackgroundColor: pointColors,
          pointRadius: 5,
          pointHoverRadius: 6,
          tooltipsMap: this.customTooltipsMap,
        },
      ] as any;
      this.lineChartLabels = chartLabels;

      const tooltipsMapCopy = { ...this.customTooltipsMap };

      this.lineChartOptions.tooltips = {
        callbacks: {
          label: function (tooltipItem, data) {
            const dataset = data.datasets[tooltipItem.datasetIndex] as any;
            const tooltipsMap = dataset.tooltipsMap || {};
            const label = data.labels?.[tooltipItem.index];
            const values = tooltipsMap[label];

            if (values && values.length > 1) {
              return `${values.join(', ')}`;
            } else if (values && values.length === 1) {
              return `${values[0]}`;
            }
          },
        },
      };
    } else if (
      this.period === 'M' ||
      this.period === 'Y' ||
      this.period === 'Custom Range'
    ) {
      if (!this.plotData || this.plotData.length === 0) return;

      const groupedData: { [dateKey: string]: number[] } = {};
      this.customTooltipsMap = {};

      this.plotData.forEach((entry) => {
        if (entry.value !== null) {
          const date = new Date(entry.date);
          const dateKey = date.toISOString().split('T')[0];

          if (!groupedData[dateKey]) {
            groupedData[dateKey] = [];
          }
          groupedData[dateKey].push(entry.value);
        }
      });

      const pointColors: string[] = [];

      Object.keys(groupedData)
        .sort()
        .forEach((dateKey) => {
          const date = new Date(dateKey);
          const values = groupedData[dateKey];
          const avg = this.average(values);

          chartData.push(avg);

          const dd = String(date.getDate()).padStart(2, '0');
          const mm = String(date.getMonth() + 1).padStart(2, '0');
          const yyyy = date.getFullYear();

          let formattedDate = `${dd}.${mm}`;
          if (this.period === 'M') formattedDate = `${dd}.${mm}.${yyyy}`;

          chartLabels.push(formattedDate);
          this.customTooltipsMap[formattedDate] = values;

          pointColors.push(values.length > 1 ? '#275413' : '#4EA925');
        });

      this.lineChartData = [
        {
          data: chartData,
          fill: false,
          borderColor: '#4EA925',
          backgroundColor: '#4EA925',
          pointBackgroundColor: pointColors,
          pointRadius: 5,
          pointHoverRadius: 6,
          tooltipsMap: this.customTooltipsMap,
        } as any,
      ];

      this.lineChartLabels = chartLabels;

      this.lineChartOptions.tooltips = {
        callbacks: {
          label: function (tooltipItem, data) {
            const dataset = data.datasets[tooltipItem.datasetIndex] as any;
            const tooltipsMap = dataset.tooltipsMap || {};
            const label = data.labels?.[tooltipItem.index];
            const values = tooltipsMap[label];

            if (values && values.length > 1) {
              return `${values.join(', ')}`;
            } else if (values && values.length === 1) {
              return `${values[0]}`;
            }
          },
        },
      };
    }

    console.log(this.lineChartData, this.lineChartLabels);
  }

  getWeekDays = (lang: string): string[] => {
    const baseDate = new Date(2021, 7, 2);
    return [...Array(7)].map((_, i) => {
      const date = new Date(baseDate);
      date.setDate(baseDate.getDate() + i);
      return date.toLocaleDateString(lang, { weekday: 'short' });
    });
  };

  private average(values: number[]): number {
    return values.reduce((sum, val) => sum + val, 0) / values.length;
  }
}
